import React, { useState, useRef, useEffect } from 'react';
import { cachedExamples } from '../cached_examples';
import CurveView from '../components/graph/CurveView';
import { highlightMatches, highlightMultipleMatches2 } from '../utils/highlightMatches';
import useExampleData from '../hooks/useExampleData';
import { auth } from '../utils/firebase';

const ExpBuilderPage = () => {
  // Graph data states
  const [graphData, setGraphData] = useState(null);
  const [selectedElementData, setSelectedElementData] = useState(null);

  // Single-term search highlight for user-typed search
  const [searchTerm, setSearchTerm] = useState('');

  // Multi highlight references from a single selected node
  const [connectedRefs, setConnectedRefs] = useState([]);

  // Multi highlight references for the “Trace” button
  const [traceRefs, setTraceRefs] = useState([]);

  // Chat states
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const chatEndRef = useRef(null);

  // Lock the chat after one user question
  const [chatLocked, setChatLocked] = useState(false);

  // Example data (we'll just use example6 as our source text)
  const { exampleData6 } = useExampleData();
  const [sourceText, setSourceText] = useState('');

  // Separate states for found vs. displayed node IDs
  const [foundEntities, setFoundEntities] = useState([]); 
  const [traceEntities, setTraceEntities] = useState([]);

  // Load the Tesla FAQ example
  const handleTeslaFAQClick = () => {
    setSourceText(exampleData6 || '');
    setGraphData(cachedExamples.example8);

    // Reset other states
    setSearchTerm('');
    setConnectedRefs([]);
    setTraceRefs([]);
    setSelectedElementData(null);
    setChatMessages([]);
    setChatLocked(false);
    setFoundEntities([]);
    setTraceEntities([]);
  };

  // ================ Highlighting Logic ================
  // Single-term highlight
  const singleHighlightedHTML = highlightMatches(sourceText, searchTerm);

  // Multi-term highlight from connectedRefs + traceRefs
  const combinedRefQueries = [...connectedRefs, ...traceRefs];
  const finalHighlightedHTML = highlightMultipleMatches2(singleHighlightedHTML, combinedRefQueries);

  // The "Connect" button near the selected node
  const handleConnect = () => {
    if (!selectedElementData) return;
    const references = selectedElementData.references || [];

    const colorPalette = ['#ffe599', '#d0e0e3', '#f9cb9c', '#b6d7a8', '#cfe2f3'];
    const newRefs = references.map((ref, idx) => ({
      query: ref,
      color: colorPalette[idx % colorPalette.length],
    }));
    setConnectedRefs(newRefs);
  };

  // Trace button logic
  const handleTrace = () => {
    if (!graphData?.nodes) return;

    const colorPalette = ['#ffd1dc', '#d1ffd6', '#d1e0ff', '#fbe5d6', '#e5d6fb'];
    const newTraceRefs = [];

    foundEntities.forEach((id, index) => {
      const node = graphData.nodes.find((n) => n.id === id);
      if (node && node.references && node.references.length > 0) {
        node.references.forEach((ref, refIdx) => {
          newTraceRefs.push({
            query: ref,
            color: colorPalette[(index + refIdx) % colorPalette.length],
          });
        });
      }
    });

    setTraceRefs(newTraceRefs);
    setTraceEntities(foundEntities);
  };

  // Helper to form a display label for node vs. edge
  function getDisplayLabel(elem, graph) {
    if (!elem) return 'No element selected';
    if (typeof elem.source !== 'undefined' && typeof elem.target !== 'undefined') {
      const sourceNode = graph?.nodes?.find(n => n.id === elem.source);
      const targetNode = graph?.nodes?.find(n => n.id === elem.target);
      const sourceLabel = sourceNode ? sourceNode.label : elem.source;
      const targetLabel = targetNode ? targetNode.label : elem.target;
      const edgeLabel = elem.label || '';
      if (elem.label === 'type of') {
        return `${targetLabel} ${edgeLabel} ${sourceLabel}`;
      } else {
        return `${sourceLabel} ${edgeLabel} ${targetLabel}`;
      }
    }
    return elem.label || 'No element selected';
  }

  // ================ Chat Logic ================
  const handleSendMessage = async () => {
    if (chatLocked || !graphData) return;
    const trimmed = chatInput.trim();
    if (!trimmed) return;

    setIsGenerating(true);
    const newUserMessage = { role: 'user', message: trimmed };
    setChatMessages((prev) => [...prev, newUserMessage]);
    setChatInput('');
    setChatLocked(true);

    try {
      const token = await auth.currentUser.getIdToken(true);

      const payload = {
        messages: [...chatMessages, newUserMessage],
        source: sourceText,
        graph: graphData
      };

      const response = await fetch('https://atlaz-api.com/generate-response', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Stream response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop();

        for (const line of lines) {
          if (line.trim()) {
            try {
              const parsed = JSON.parse(line);
              // Store returned entity IDs in foundEntities
              if (Array.isArray(parsed.entities)) {
                setFoundEntities(parsed.entities);
              }

              // If chunk has messages
              if (parsed.messages && Array.isArray(parsed.messages)) {
                parsed.messages.forEach((msg) => {
                  if (msg.role === 'assistant') {
                    setChatMessages((prev) => [...prev, msg]);
                  }
                });
              }

            } catch (err) {
              console.error('Error parsing JSON chunk:', err, 'Line:', line);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      setChatMessages(prev => [
        ...prev,
        { role: 'assistant', message: 'Oops, something went wrong while generating a response.' }
      ]);
    } finally {
      setIsGenerating(false);
    }
  };

  // Scroll chat to bottom
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  // CHANGED: Reset just the chat-related states
  const handleResetChat = () => {
    setChatMessages([]);
    setChatLocked(false);
    setFoundEntities([]);
    setTraceEntities([]);
    setTraceRefs([]);
    setConnectedRefs([]);
  };

  const label = getDisplayLabel(selectedElementData, graphData);
  const info = selectedElementData?.info || 'Select a node or edge to view details';

  return (
    <div className="min-h-screen w-full overflow-y-auto p-4 bg-gray-50">
      <div className="max-w-7xl mx-auto flex flex-col gap-6">
        
        {/* Top row: example + graph */}
        <div className="flex gap-6">
          {/* LEFT Column */}
          <div className="w-1/2 border rounded p-4 flex flex-col gap-4" style={{ height: '1020px' }}>
            <div>
              <h1 className="text-2xl font-bold">Preview - Knowledge Graph Chat</h1>
              <p className="text-gray-700 leading-relaxed">
                Load an example and ask a question to the assistant. The assistant will generate a response 
                based on the knowledge graph, allowing you to trace the source data and increase reliability.
              </p>
            </div>

            {/* Example loading */}
            <div>
              <h2 className="text-lg font-semibold mb-2">Available Example</h2>
              <div
                className="border border-gray-300 px-3 py-1 rounded cursor-pointer hover:bg-gray-100 inline-block"
                onClick={handleTeslaFAQClick}
              >
                <strong>Tesla FAQ</strong>
              </div>
            </div>

            {/* Selected Element Details */}
            {graphData && (
              <div className="border border-gray-300 p-2 rounded bg-white">
                <h2 className="text-lg font-semibold mb-1">Element Details</h2>
                <p><strong>Name:</strong> {label}</p>
                <p><strong>Info:</strong> {info}</p>
                {selectedElementData?.references?.length > 0 && (
                  <>
                    <strong>References:</strong>
                    <ul className="list-disc pl-5 text-sm text-gray-700">
                      {selectedElementData.references.map((r, i) => <li key={i}>{r}</li>)}
                    </ul>
                    <button
                      onClick={handleConnect}
                      className="mt-2 px-3 py-1 bg-gray-200 rounded hover:bg-gray-300 text-sm font-semibold"
                    >
                      Trace
                    </button>
                  </>
                )}
              </div>
            )}

            {/* Graph display */}
            {graphData && (
              <div
                className="border border-gray-300 rounded p-2 bg-white flex-1 overflow-auto"
                style={{ height: '824px' }}
              >
                <h2 className="text-lg font-semibold mb-2">Retrieved Graph</h2>
                <div className="h-full">
                  <CurveView
                    graphData={graphData}
                    onElementSelect={(d) => setSelectedElementData(d)}
                    highlightNodes={traceEntities}  
                  />
                </div>
              </div>
            )}
          </div>

          {/* RIGHT Column (Source text) */}
          <div className="w-1/2 border rounded p-4 flex flex-col gap-4" style={{ height: '1020px' }}>
            <div>
              <h2 className="text-lg font-semibold">Search in Source</h2>
              <input
                type="text"
                className="border border-gray-300 rounded p-2 w-full"
                placeholder="Type a term to find..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* The text display with combined highlighting */}
            <div
              className="border border-gray-300 rounded p-2 text-sm text-gray-800 overflow-auto flex-1"
              style={{ height: '820px', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: finalHighlightedHTML }}
            />
          </div>
        </div>

        {/* Chat Section */}
        <div className="w-full border rounded p-4 bg-white flex flex-col gap-4" style={{ height: '400px' }}>
          <h2 className="text-lg font-semibold mb-2">Chat with Assistant</h2>

          {/* The chat display area */}
          <div className="flex-1 overflow-auto border border-gray-200 rounded p-3 bg-gray-50">
            {chatMessages.map((msg, idx) => {
              const isUser = msg.role === 'user';
              return (
                <div
                  key={idx}
                  className={`mb-4 flex flex-col ${
                    isUser ? 'items-start' : 'items-end'
                  }`}
                >
                  {/* Label (User or Assistant) */}
                  <div
                    className={`text-xs font-bold mb-1 ${
                      isUser ? 'text-blue-700' : 'text-green-700'
                    }`}
                  >
                    {isUser ? 'You' : 'Assistant'}
                  </div>

                  {/* Bubble */}
                  <div
                    className={`inline-block rounded-lg px-4 py-2 max-w-lg ${
                      isUser
                        ? 'bg-blue-600 text-white'
                        : 'bg-green-100 text-gray-800'
                    }`}
                  >
                    {msg.message}
                  </div>
                </div>
              );
            })}
            <div ref={chatEndRef} />
          </div>

          {/* Chat controls */}
          <div className="flex items-center gap-2">
            {/* CHANGED: “Reset” button that only clears chat */}
            {chatLocked && (
              <button
                onClick={handleResetChat}
                className="px-4 py-2 bg-gray-300 text-white rounded hover:bg-gray-200"
              >
                Reset
              </button>
            )}
            <textarea
              className="flex-1 border border-gray-300 rounded p-2 h-12 resize-none"
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              placeholder="Type your question..."
              disabled={!graphData || isGenerating || chatLocked}
            />
            <button
              onClick={handleSendMessage}
              disabled={!graphData || isGenerating || chatLocked}
              className="px-4 py-2 bg-gray-300 text-white rounded hover:bg-gray-200"
            >
              {isGenerating ? '...' : 'Send'}
            </button>
            <button
              onClick={handleTrace}
              className="px-4 py-2 bg-success text-white rounded hover:bg-success"
              disabled={foundEntities.length === 0}
            >
              Trace
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpBuilderPage;