// SpeciesPortal.jsx
import React from "react"
import { Routes, Route } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { SidebarProvider } from "./components/ui/sidebar.tsx"
import { AppSidebar } from "./components/features/AppSidebar.tsx"

import Index from "./pages/Index.tsx"
import Upload from "./pages/Upload.tsx"
import SpeciesDetail from "./pages/SpeciesDetail.tsx"
import { SpeciesErrorBoundaryWrapper } from "./SpeciesErrorBoundary.tsx"
const queryClient = new QueryClient()

const SpeciesPortal = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SidebarProvider>
        {/* 
           This is the layout for your species portal.
           If you don't want the sidebar or want to unify 
           the layout, adapt accordingly. 
        */}
        <div className="flex min-h-screen w-full">
          <AppSidebar />
          <main className="flex-1 overflow-x-hidden">
          <SpeciesErrorBoundaryWrapper>
              <Routes>
                {/* Because we will mount this at /species/*, 
                    you can use relative paths here. */}
                <Route index element={<Index />} />
                <Route path="upload" element={<Upload />} />
                <Route path=":id" element={<SpeciesDetail />} />
              </Routes>
          </SpeciesErrorBoundaryWrapper>
          </main>
        </div>
      </SidebarProvider>
    </QueryClientProvider>
  )
}

export default SpeciesPortal