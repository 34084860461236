// src/features/species/pages/SpeciesDetail.tsx

import React from "react";
import { useParams, Link } from "react-router-dom";
import { speciesData } from "../data/mockSpecies.ts";
import { mockPDFExtractions } from "../data/mockPDFExtraction.ts";
import { Card, CardContent } from "../components/ui/card.tsx";
import { Button } from "../components/ui/button.tsx";
import { ArrowLeft } from "lucide-react";
import { FileText } from "lucide-react";
// Updated field labels
const fieldLabels: Record<
  keyof (typeof mockPDFExtractions)[number]["fields"],
  string
> = {
  Extant_Populations: "Extant populations",
  Extinct_Populations: "Extinct populations",
  Population_Sizes_Lower_Bound: "Population Sizes (Lower Bound)",
  Population_Sizes_Upper_Bound: "Population Sizes (Upper Bound)",
  Population_Less_Than_5000_Individuals: "Populations - Less Than 5000 Individuals",
  Phylogenetic_Geographic_Studies: "Phylogenetic/Geographic Studies",
  Population_Genetic_Studies: "Population Genetic Studies",
  DNA_based_Temporal_Monitoring: "DNA-based Temporal Monitoring",
};

// Define grouped fields with their indicators
const groupedFields: Array<{
  fields: Array<keyof typeof fieldLabels>;
  indicator: string;
}> = [
  {
    fields: ["Extant_Populations", "Extinct_Populations"],
    indicator: "Indikator 1",
  },
  {
    fields: ["Population_Sizes_Lower_Bound", "Population_Sizes_Upper_Bound"],
    indicator: "Indikator 2",
  },
  {
    fields: [
      "Population_Less_Than_5000_Individuals",
      "Phylogenetic_Geographic_Studies",
      "Population_Genetic_Studies",
      "DNA_based_Temporal_Monitoring",
    ],
    indicator: "Indikator 3",
  },
];

export default function SpeciesDetail() {
  const { id } = useParams<{ id: string }>();
  const speciesId = Number(id);

  // Retrieve species information
  const species = speciesData.find((s) => s.id === speciesId);
  const pdfExtractions = mockPDFExtractions[speciesId] || [];

  if (!species) {
    return (
      <div className="p-6">
        <p className="text-red-500">Species not found.</p>
        <Link to="/species">
          <Button variant="outline" className="mt-4">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      {/* Header Section */}
      <div className="flex items-center space-x-4">
        <Link to="/species">
          <Button variant="outline">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back
          </Button>
        </Link>
        <h1 className="text-2xl font-bold">
          {species.name} (<em>{species.latinName}</em>)
        </h1>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Left Column: Variables Table */}
        <Card className="lg:col-span-2">
          <CardContent className="p-4 space-y-6">
            {groupedFields.map((group, groupIndex) => (
              <div
                key={groupIndex}
                className="p-4 border rounded-lg space-y-2 bg-gray-50"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {pdfExtractions.map((pdf, pdfIndex) => (
                    <div key={pdfIndex} className="space-y-2">
                      {/* Show the PDF name for each column */}
                      <div className="text-right mb-1">
  <div className="relative group inline-flex items-center justify-end cursor-pointer">
    <FileText className="h-4 w-4 text-gray-600" />
    {/* Tooltip / Hidden text that appears on hover */}
    <span className="absolute bottom-full mb-1 hidden w-auto whitespace-nowrap rounded bg-black px-2 py-1 text-xs text-white group-hover:block">
      {pdf.pdfName}
    </span>
  </div>
</div>

                      {/* Data Points */}
                      <ul className="space-y-1">
                        {group.fields.map((field, index) => (
                          <li key={index} className="text-sm">
                            <div className="flex justify-between">
                              <span>
                                {pdfIndex === 0 ? fieldLabels[field] : ""}
                              </span>
                              <span className="text-gray-600">
                                {pdf.fields[field] !== undefined
                                  ? pdf.fields[field].toString()
                                  : "-"}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                {/* Indicator */}
                <div className="text-center text-sm font-semibold mt-2 pl-24">
                {group.indicator}:{" "}
                <span className="text-green-700">
                  {pdfExtractions[0]?.fields[group.indicator] || "0,00"}
                </span>
              </div>
              </div>
            ))}
          </CardContent>
        </Card>
        {/* Right Column: Image and Summary */}
        <Card>
          <CardContent className="p-4">
            <img
              src="/alkon.png"
              alt={species.name}
              className="w-full h-48 object-cover rounded-lg"
            />
            <h2 className="text-lg font-bold mt-4">{species.name}</h2>
            <p className="italic">{species.latinName}</p>
            <div className="mt-4 space-y-2">
              {groupedFields.map((group, index) => (
                <div
                  key={index}
                  className="flex justify-between text-sm font-medium"
                >
                  <span>{group.indicator}</span>
                  <span className="text-green-700">
                    {pdfExtractions[0]?.fields[group.indicator] || "0,00"}
                  </span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}