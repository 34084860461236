// src/features/species/components/species/AddSpeciesForm.tsx

import { useState } from "react"
import { Card } from "../ui/card.tsx"
import { Input } from "../ui/input.tsx"
import { Button } from "../ui/button.tsx"
import { ImageSearch } from "../features/ImageSearch.tsx"
import { toast } from "sonner"

// Import your API service method
import { createSpecies } from "../../lib/api.ts"  // <-- Adjust path if needed

export function AddSpeciesForm() {
  const [name, setName] = useState("")
  const [latinName, setLatinName] = useState("")
  // We no longer store or ask for status or description
  const [selectedImage, setSelectedImage] = useState("")

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      // Call our service function instead of fetch
      const data = await createSpecies({
        name,
        latinName,
        imageKey: selectedImage,
      })

      toast.success("Species created successfully in the database!")
      console.log("New species ID:", data.id)

      // Optionally reset the form
      setName("")
      setLatinName("")
      setSelectedImage("")
    } catch (err: any) {
      toast.error("Error creating species: " + err.message)
      console.error("Error creating species:", err)
    }
  }

  return (
    <Card className="p-6 mb-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 font-semibold">Common Name</label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block mb-1 font-semibold">Latin Name</label>
          <Input
            value={latinName}
            onChange={(e) => setLatinName(e.target.value)}
            required
          />
        </div>

        {/* Only show the ImageSearch if user typed a name */}
        {name && (
          <div className="mt-4">
            <label className="block mb-2 font-semibold">
              Optional: Gather an Image for {name}
            </label>
            <ImageSearch
              speciesName={name}
              onImageSelect={(imgUrl) => setSelectedImage(imgUrl)}
            />
            {selectedImage && (
              <div className="mt-2 text-sm">
                <strong>Selected Image:</strong>
                <div className="mt-1">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="w-32 h-32 object-cover border"
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <Button type="submit" variant="default" className="mt-4 w-full">
          Create New Species
        </Button>
      </form>
    </Card>
  )
}
