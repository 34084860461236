// src/features/species/pages/SpeciesDetail.tsx

import React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getPdfExtractions, getSpeciesDetail } from "../lib/api.ts";
import { Card, CardContent } from "../components/ui/card.tsx";
import { Button } from "../components/ui/button.tsx";
import { ArrowLeft, FileText } from "lucide-react";

// Define field labels
const fieldLabels: Record<string, string> = {
  Extant_Populations: "Extant populations",
  Extinct_Populations: "Extinct populations",
  Population_Sizes_Lower_Bound: "Population Sizes (Lower Bound)",
  Population_Sizes_Upper_Bound: "Population Sizes (Upper Bound)",
  Population_Less_Than_5000_Individuals: "Populations - Less Than 5000 Individuals",
  Phylogenetic_Geographic_Studies: "Phylogenetic/Geographic Studies",
  Population_Genetic_Studies: "Population Genetic Studies",
  DNA_based_Temporal_Monitoring: "DNA-based Temporal Monitoring",
};

// Define grouped fields with their indicators
const groupedFields: Array<{
  fields: Array<string>;
  indicator: string;
}> = [
  {
    fields: ["Extant_Populations", "Extinct_Populations"],
    indicator: "Indikator 1",
  },
  {
    fields: ["Population_Sizes_Lower_Bound", "Population_Sizes_Upper_Bound"],
    indicator: "Indikator 2",
  },
  {
    fields: [
      "Population_Less_Than_5000_Individuals",
      "Phylogenetic_Geographic_Studies",
      "Population_Genetic_Studies",
      "DNA_based_Temporal_Monitoring",
    ],
    indicator: "Indikator 3",
  },
];

export default function SpeciesDetail() {
  const { id } = useParams<{ id: string }>();
  const speciesId = Number(id);

  // Fetch species details using existing endpoint
  const {
    data: species,
    isLoading: isSpeciesLoading,
    isError: isSpeciesError,
    error: speciesError,
  } = useQuery({
    queryKey: ["speciesDetail", speciesId],
    queryFn: () => getSpeciesDetail(speciesId),
    enabled: !isNaN(speciesId),
  });

  // Fetch PDF extractions using existing endpoint
  const {
    data: extractionData,
    isLoading: isExtractionLoading,
    isError: isExtractionError,
    error: extractionError,
  } = useQuery({
    queryKey: ["pdfExtractions", speciesId],
    queryFn: () => getPdfExtractions(String(speciesId)),
    enabled: !isNaN(speciesId),
  });

  // Handle loading states
  if (isSpeciesLoading || isExtractionLoading) {
    return <div className="p-6">Loading data for species {speciesId}...</div>;
  }

  // Handle errors
  if (isSpeciesError) {
    return (
      <div className="p-6">
        <p className="text-red-500">
          Error loading species details: {(speciesError as Error).message}
        </p>
        <Link to="/species">
          <Button variant="outline" className="mt-4 flex items-center">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </Button>
        </Link>
      </div>
    );
  }

  if (isExtractionError) {
    return (
      <div className="p-6">
        <p className="text-red-500">
          Error loading PDF extractions: {(extractionError as Error).message}
        </p>
        <Link to="/species">
          <Button variant="outline" className="mt-4 flex items-center">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </Button>
        </Link>
      </div>
    );
  }

  // Check if species exists
  if (!species) {
    return (
      <div className="p-6">
        <p className="text-red-500">Species not found.</p>
        <Link to="/species">
          <Button variant="outline" className="mt-4 flex items-center">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </Button>
        </Link>
      </div>
    );
  }

  // Transform DB rows to match UI structure
  const rows = extractionData?.extractions ?? [];
  const pdfExtractions = rows.map((row: any) => ({
    pdfName: row.pdf_key,
    fields: {
      Extant_Populations: row.Extant_Populations ?? null,
      Extinct_Populations: row.Extinct_Populations ?? null,
      Population_Sizes_Lower_Bound: row.Population_Sizes_Lower_Bound ?? null,
      Population_Sizes_Upper_Bound: row.Population_Sizes_Upper_Bound ?? null,
      Population_Less_Than_5000_Individuals:
        row.Population_Less_Than_5000_Individuals ?? null,
      Phylogenetic_Geographic_Studies:
        row.Phylogenetic_Geographic_Studies ?? null,
      Population_Genetic_Studies: row.Population_Genetic_Studies ?? null,
      DNA_based_Temporal_Monitoring:
        row.DNA_based_Temporal_Monitoring ?? null,
    },
  }));

  return (
    <div className="p-4 sm:p-6 space-y-6">
      {/* Header Section */}
      <div className="flex items-center space-x-4">
        <Link to="/species">
          <Button variant="outline" size="sm" className="flex items-center">
            <ArrowLeft className="mr-1 h-4 w-4" />
            Back
          </Button>
        </Link>
        <h1 className="text-xl sm:text-2xl font-bold">
          {species.name} (<em>{species.latinName}</em>)
        </h1>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 sm:gap-4">
        {/* Left Column: Variables Table */}
        <Card className="lg:col-span-2">
          <CardContent className="p-1 sm:p-2 space-y-3">
            {groupedFields.map((group, groupIndex) => (
              <div
                key={groupIndex}
                className="p-1 sm:p-2 border rounded-lg space-y-1 bg-gray-50"
              >
                {/* Increased grid columns and minimized gap */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1 sm:gap-2">
                  {pdfExtractions.map((pdf, pdfIndex) => (
                    <div key={pdfIndex} className="space-y-0.5">
                      {/* PDF Name Icon with Tooltip */}
                      <div className="text-right mb-0.5">
                        <div
                          className="relative group inline-flex items-center justify-end cursor-pointer"
                          aria-label={`PDF Name: ${pdf.pdfName}`}
                        >
                          <FileText className="h-3 w-3 text-gray-600" />
                          <span
                            className="absolute bottom-full mb-1 hidden w-auto whitespace-nowrap rounded bg-black px-1 py-0.5 text-[8px] text-white group-hover:block transition-opacity duration-200 ease-in-out"
                            role="tooltip"
                          >
                            {pdf.pdfName}
                          </span>
                        </div>
                      </div>

                      {/* Data Points */}
                      <ul className="space-y-0.5 text-[9px] sm:text-[10px]">
                        {group.fields.map((field, idx) => (
                          <li key={idx} className="flex justify-between">
                            {/* Show label only in the first PDF's column */}
                            <span className="font-medium">
                              {pdfIndex === 0 ? fieldLabels[field] : ""}
                            </span>
                            <span className="text-gray-600">
                              {pdf.fields[field] != null
                                ? pdf.fields[field].toString()
                                : "-"}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>

                {/* Indicator Line */}
                <div className="text-center text-xs font-semibold mt-1 pl-10">
                  {group.indicator}:{" "}
                  <span className="text-green-700">
                    {pdfExtractions[0]?.fields[group.indicator] || "0,00"}
                  </span>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>

        {/* Right Column: Image and Summary */}
        <Card>
          <CardContent className="p-1 sm:p-2">
            {/* Dynamic Image Source */}
            <img
              src={species.image || "/alkon.png"} // Use species.image if available
              alt={species.name}
              className="w-full h-32 sm:h-40 object-cover rounded-lg"
              loading="lazy" // Enables lazy loading
            />
            <h2 className="text-xs sm:text-sm font-bold mt-2">
              {species.name}
            </h2>
            <p className="italic text-[8px] sm:text-[10px]">
              {species.latinName}
            </p>

            <div className="mt-1 space-y-1">
              {groupedFields.map((group, index) => (
                <div
                  key={index}
                  className="flex justify-between text-[8px] sm:text-[10px] font-medium"
                >
                  <span>{group.indicator}</span>
                  <span className="text-green-700">
                    {pdfExtractions[0]?.fields[group.indicator] || "0,00"}
                  </span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
