import { Check } from "lucide-react"
import { Button } from "../ui/button.tsx"

interface ConfirmationMessageProps {
  onReset: () => void
}

export const ConfirmationMessage = ({ onReset }: ConfirmationMessageProps) => {
  return (
    <div className="bg-white shadow rounded-lg p-6 text-center">
      <Check className="mx-auto h-12 w-12 text-primary mb-4" />
      <h2 className="text-2xl font-medium mb-2">Data Successfully Added</h2>
      <p className="text-gray-600 mb-4">
        The species data has been successfully added to our database.
      </p>
      <Button onClick={onReset}>
        Upload Another
      </Button>
    </div>
  )
}