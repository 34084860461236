// src/components/graph/KnowledgeGraph.jsx
import React, { useEffect, useRef, useMemo } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import Cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';

Cytoscape.use(dagre);

const KnowledgeGraph = ({
  data,
  height = 600,
  onElementSelect,
  highlightNodes = []
}) => {
  const cyRef = useRef(null);
  const prevDataRef = useRef(null);

  // Define a hierarchical layout
  const layout = {
    name: 'dagre',
    rankDir: 'TB', // or 'LR'
    nodeSep: 50,
    edgeSep: 10,
    rankSep: 100,
    ranker: 'longest-path'
  };

  // Prepare elements from data
  const elements = useMemo(() => {
    const clusterElements = (data.clusters || []).map((cluster) => ({
      data: {
        id: cluster.id,
        label: cluster.label,
        color: cluster.color,
        shape: cluster.shape,
      },
      classes: 'cluster',
      group: 'nodes',
    }));

    const nodeElements = (data.nodes || []).map((node) => {
      const nodeData = {
        id: node.id,
        label: node.label,
        color: node.color,
        shape: node.shape,
      };
      if (node.parent) nodeData.parent = node.parent;
      if (node.info) nodeData.info = node.info;
      if (node.references) nodeData.references = node.references;
      return { data: nodeData, group: 'nodes' };
    });

    const edgeElements = (data.links || []).map((link) => {
      const edgeData = {
        id: link.id || `${link.source}-${link.target}-${Math.random()}`,
        source: link.source,
        target: link.target,
        color: link.color || 'black',
        width: link.width || 2,
        style: link.style || 'solid',
        arrowShape: link.arrowShape || 'triangle',
        label: link.label,
      };
      if (link.info) edgeData.info = link.info;
      if (link.references) edgeData.references = link.references;

      // "type of" edges get a larger minLen for hierarchy
      if (link.label && link.label.toLowerCase() === 'type of') {
        edgeData.minLen = 3;
      } else {
        edgeData.minLen = 1;
      }
      return { data: edgeData, group: 'edges' };
    });

    return [...clusterElements, ...nodeElements, ...edgeElements];
  }, [data]);

  // Cytoscape styling
  const stylesheet = [
    // Default node style
    {
      selector: 'node',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        label: 'data(label)',
        'text-wrap': 'wrap',
        'text-max-width': 100,
        'font-size': '14px',
        'text-valign': 'center',
        'text-halign': 'center',
        // Note: these set the node size based on the label,
        // but we'll override them in .highlighted
        width: 'label',
        height: 'label',
        padding: '10px',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    // Parent cluster style
    {
      selector: 'node:parent',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        'border-color': '#a0a0a0',
        'border-width': 2,
        label: 'data(label)',
        'text-valign': 'top',
        'text-halign': 'center',
        'text-wrap': 'wrap',
        'text-max-width': 150,
        'font-size': '16px',
        padding: '20px',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    // Default edge style
    {
      selector: 'edge',
      style: {
        'line-color': 'data(color)',
        width: 'data(width)',
        'line-style': 'data(style)',
        'target-arrow-shape': 'data(arrowShape)',
        'target-arrow-color': 'data(color)',
        'curve-style': 'bezier',
        label: 'data(label)',
        'font-size': '12px',
        color: '#555',
        'text-background-color': '#fff',
        'text-background-opacity': 0.7,
        'text-rotation': 'autorotate',
        'text-margin-y': '-10px',
        'edge-text-rotation': 'autorotate',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    // Parent cluster styling
    {
      selector: '.cluster',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        label: 'data(label)',
        'text-valign': 'top',
        'text-halign': 'center',
        'font-size': '16px',
        'border-width': 2,
        'border-color': '#a0a0a0',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    // Highlighted style — bigger node size, thicker border
    {
      selector: '.highlighted',
      style: {
        'border-color': '#000000',
        'border-width': 4,
        'background-color': '#800080',
        width: 100,
        height: 100,
        'font-size': '16px', // optional: larger label font
      },
    },
  ];

  // Watch highlightNodes array changes
  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;

    // Remove highlight from all
    cy.nodes().removeClass('highlighted');

    // Add highlight class to each ID in highlightNodes
    highlightNodes.forEach((id) => {
      const selector = `node[id="${id}"]`;
      cy.$(selector).addClass('highlighted');
    });
  }, [highlightNodes]);

  // Manage new data
  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;

    const dataString = JSON.stringify(data);
    if (prevDataRef.current === dataString) {
      // Data hasn't changed
      return;
    }
    prevDataRef.current = dataString;

    // Clear existing
    cy.elements().remove();
    cy.add(elements);

    // Layout
    cy.layout(layout).run();

    // Setup click handlers
    cy.off('tap');
    cy.on('tap', 'node, edge', (e) => {
      const elementData = e.target.data();
      if (onElementSelect) {
        onElementSelect(elementData);
      }
    });

    cy.on('tap', (e) => {
      if (e.target === cy && onElementSelect) {
        onElementSelect(null);
      }
    });
  }, [data, elements, layout, onElementSelect]);

  return (
    <div style={{ width: '100%', height: `${height}px` }}>
      <CytoscapeComponent
        cy={(cy) => { cyRef.current = cy; }}
        elements={[]} // We'll manage elements manually
        stylesheet={stylesheet}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default KnowledgeGraph;