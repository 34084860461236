import React, { useState, useEffect } from 'react';
import Modal from '../components/common/Modal';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { cachedExamples } from '../cached_examples';
import CurveView from '../components/graph/CurveView';
import { FaCog, FaInfoCircle } from 'react-icons/fa';

const Home = () => {
  const [selectedExample, setSelectedExample] = useState(null);
  const [showBetaModal, setShowBetaModal] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  // For viewing source text
  const [showSourceModal, setShowSourceModal] = useState(false);
  const [sourceText, setSourceText] = useState('');
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [demoName, setDemoName] = useState('');
  const [demoEmail, setDemoEmail] = useState('');
  const [demoCompany, setDemoCompany] = useState('');
  // New optional message field
  const [demoOptionalMessage, setDemoOptionalMessage] = useState('');

  // Feedback after sending
  const [demoMessage, setDemoMessage] = useState('');

  // Example data
  const [exampleData1, setExampleData1] = useState('');
  const [exampleData2, setExampleData2] = useState('');
  const [exampleData3, setExampleData3] = useState('');
  const [exampleData4, setExampleData4] = useState('');

  // Fetch the example files on mount
  useEffect(() => {
    fetch('/example1.txt')
      .then((response) => response.text())
      .then((data) => setExampleData1(data))
      .catch((err) => console.error('Error loading example1:', err));

    fetch('/example2.txt')
      .then((response) => response.text())
      .then((data) => setExampleData2(data))
      .catch((err) => console.error('Error loading example2:', err));

    fetch('/example3.txt')
      .then((response) => response.text())
      .then((data) => setExampleData3(data))
      .catch((err) => console.error('Error loading example3:', err));

    fetch('/example4.txt')
      .then((response) => response.text())
      .then((data) => setExampleData4(data))
      .catch((err) => console.error('Error loading example4:', err));
  }, []);

  // Beta Access Modal
  const handleOpenModal = () => {
    setShowBetaModal(true);
  };
  const handleCloseModal = () => {
    setShowBetaModal(false);
  };

  // Book Demo Modal
  const handleOpenDemoModal = () => {
    setShowDemoModal(true);
  };
  const handleCloseDemoModal = () => {
    setShowDemoModal(false);
    setDemoName('');
    setDemoEmail('');
    setDemoCompany('');
    setDemoOptionalMessage('');
    setDemoMessage('');
  };

  // Beta sign-up
  const handleBetaSignUp = (e) => {
    e.preventDefault();
    const email = e.target.betaEmail.value;

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          to_name: 'Rasmus',
          from_name: email,
          message: 'User entered message here',
          user_email: email,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Request received. We will be in touch soon!');
          handleCloseModal();
        },
        (err) => {
          console.error('FAILED...', err);
          alert('Failed to send email.');
        }
      );
  };

  // === NEW: Book Demo form submission ===
  const handleDemoSignUp = (e) => {
    e.preventDefault();
    // Use emailjs to send the form data
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: demoName,
          user_email: demoEmail,
          message: `Company/Role: ${demoCompany}
Message: ${demoOptionalMessage}`,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setDemoMessage('Your request has been received. We will contact you soon!');
        },
        (err) => {
          console.error('FAILED...', err);
          setDemoMessage('Failed to send your request. Please try again later.');
        }
      );
  };

  // Examples
  const examples = [
    {
      key: 'example1',
      label: 'Tesla Quarterly Report',
      data: cachedExamples.example1,
      text: exampleData1,
    },
    {
      key: 'example2',
      label: 'Introductory Abstract Algebra',
      data: cachedExamples.example2,
      text: exampleData2,
    },
    {
      key: 'example3',
      label: 'CRISPR-Cas9 technology...',
      data: cachedExamples.example3,
      text: exampleData3,
    },
    {
      key: 'example4',
      label: 'Attention is all you need!',
      data: cachedExamples.example4,
      text: exampleData4,
    },
  ];

  const handleGenerate = (exampleKey) => {
    if (isGenerating) return;
    setIsGenerating(true);
    setTimeout(() => {
      setSelectedExample(exampleKey);
      setIsGenerating(false);
    }, 2000);
  };

  const handleViewSource = (exampleKey) => {
    const example = examples.find((ex) => ex.key === exampleKey);
    if (example && example.text) {
      setSourceText(example.text);
      setShowSourceModal(true);
    }
  };

  const selectedData = selectedExample
    ? examples.find((ex) => ex.key === selectedExample)?.data
    : null;

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Hero Section */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-6 py-10 text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Knowledge Graphs for a New Era of AI
          </h1>
          <p className="text-gray-600 leading-relaxed max-w-3xl mx-auto">
            Atlaz empowers you to build, visualize, and deploy knowledge graphs—
            turning raw data into powerful information structures for accurate,
            explainable, and safe AI.
          </p>
          <div className="mt-8 space-x-4">
            <Link
              to="/login"
              className="inline-block px-6 py-3 rounded-md text-sm font-medium text-white bg-success hover:bg-success transition"
            >
              Build a Graph
            </Link>
            <Link
              to="/about"
              className="inline-block px-6 py-3 rounded-md text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 transition"
            >
              Learn More
            </Link>
            <button
              onClick={handleOpenDemoModal}
              className="inline-block px-6 py-3 rounded-md text-sm font-medium text-white bg-success hover:bg-success transition"
            >
              Book a Demo
            </button>
          </div>
        </div>
      </header>

      {/* Main Content Section */}
      <main className="flex-grow bg-white">
        <div className="max-w-5xl mx-auto px-6 py-16">
          <div className="flex">
            {/* Left column for examples */}
            <div className="w-1/2 pr-6">
              <h2 className="text-lg font-semibold mb-2">Examples</h2>
              <div className="space-y-4">
                {examples.map((ex) => (
                  <div
                    key={ex.key}
                    className="border border-gray-300 p-4 rounded flex justify-between items-center space-x-2"
                  >
                    <span className="flex-1">{ex.label}</span>
                    <button
                      onClick={() => handleViewSource(ex.key)}
                      className="text-gray-200 hover:text-gray-300"
                      title="View Source"
                    >
                      <FaInfoCircle size={18} />
                    </button>
                    <button
                      onClick={() => handleGenerate(ex.key)}
                      className="text-[#A9C47C] hover:text-[#8DA25F]"
                      title="Generate"
                    >
                      <FaCog size={20} />
                    </button>
                  </div>
                ))}
              </div>

                

              {isGenerating && (
                <div className="mt-4 text-center text-gray-500">
                  Generating graph...
                </div>
              )}

              {selectedData && !isGenerating && (
                <div className="mt-6 bg-white border border-gray-300 rounded p-4">
                  <h3 className="text-lg font-bold mb-2 text-gray-800">
                    Generated Graph:
                  </h3>
                  <CurveView graphData={selectedData} height={200} />
                </div>
              )}
            </div>

            {/* Right column text */}
            <div className="w-3/4">
              <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
                Power your application with Knowledge Graphs
              </h2>
              <div className="text-gray-700 leading-relaxed space-y-6">
                <p>
                  Log in to build your own graph, based on your own data—it's
                  free! Or try an example on the left!
                </p>
                <p>
                  We are currently inviting beta users to try our knowledge
                  engine, Raspberry. Click below to sign up for beta access!
                </p>
                <div className="mt-10 text-center">
                  <button
                    onClick={handleOpenModal}
                    className="inline-block px-6 py-3 rounded-md text-sm font-medium text-white bg-[#A9C47C] hover:bg-[#8DA25F] transition"
                  >
                    Sign up for Beta Access!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* SOURCE TEXT MODAL */}
      <Modal
        isOpen={showSourceModal}
        onClose={() => setShowSourceModal(false)}
        title="Source Text"
      >
        <div className="overflow-auto max-h-96 whitespace-pre-wrap text-gray-700 text-sm">
          {sourceText}
        </div>
      </Modal>

      {/* BETA SIGN-UP MODAL */}
      <Modal isOpen={showBetaModal} onClose={handleCloseModal} title="Request Beta Access">
        <form onSubmit={handleBetaSignUp}>
          <label className="block mb-1 font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="betaEmail"
            placeholder="you@example.com"
            className="w-full p-3 border border-gray-300 rounded-md mb-4"
            required
          />
          <button
            type="submit"
            className="w-full p-3 bg-[#A9C47C] text-white font-semibold rounded-md"
          >
            Request Beta Access
          </button>
        </form>
      </Modal>

      {/* NEW: BOOK DEMO MODAL */}
      <Modal
        isOpen={showDemoModal}
        onClose={handleCloseDemoModal}
        title="Book a Demo"
      >
        {demoMessage ? (
          <p className="text-green-600 mb-4">{demoMessage}</p>
        ) : (
          <p className="mb-4 text-gray-700">
            Please provide your info to schedule a demo.
          </p>
        )}

        <form onSubmit={handleDemoSignUp} className="space-y-4">
          <div>
            <label className="block mb-1 font-medium text-gray-700">
              Your Name
            </label>
            <input
              type="text"
              value={demoName}
              onChange={(e) => setDemoName(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              value={demoEmail}
              onChange={(e) => setDemoEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-700">
              Company or Role
            </label>
            <input
              type="text"
              value={demoCompany}
              onChange={(e) => setDemoCompany(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md"
              required
            />
          </div>
          {/* OPTIONAL MESSAGE FIELD */}
          <div>
            <label className="block mb-1 font-medium text-gray-700">
              Optional Message (e.g. questions or use case)
            </label>
            <textarea
              value={demoOptionalMessage}
              onChange={(e) => setDemoOptionalMessage(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md"
              placeholder="Your message..."
            />
          </div>

          {!demoMessage && (
            <button
              type="submit"
              className="w-full p-3 bg-success text-white font-semibold rounded-md hover:bg-success"
            >
              Submit
            </button>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default Home;