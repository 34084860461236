// src/data/mockSpecies.ts

export interface Species {
  id: number
  name: string
  latinName: string
  description: string
  status: string
  image?: string  // <--- Added this optional field
}

export const speciesData: Species[] = [
  {
    id: 1,
    name: "Alkonblåvinge",
    latinName: "Phengaris alcon",
    description: "A rare butterfly species found in specific habitats",
    status: "Endangered",
  },
  {
    id: 2,
    name: "Klockgentiana",
    latinName: "Gentiana pneumonanthe",
    description: "A perennial herbaceous plant with blue flowers",
    status: "Vulnerable",
  },
  {
    id: 3,
    name: "Tysk Gist",
    latinName: "Saccharomyces pastorianus",
    description: "A species of yeast used in beer fermentation",
    status: "Stable",
  },
  {
    id: 4,
    name: "Svartbent Sköldbagge",
    latinName: "Cassida murraea",
    description: "A leaf beetle species found in Europe",
    status: "Near Threatened",
  },
  {
    id: 5,
    name: "Större Vattensalamander",
    latinName: "Triturus cristatus",
    description: "An amphibian species found in standing water",
    status: "Vulnerable",
  },
]