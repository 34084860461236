// src/hooks/useExampleData.js
import { useState, useEffect } from 'react';

const useExampleData = () => {
  const [exampleData1, setExampleData1] = useState('');
  const [exampleData2, setExampleData2] = useState('');
  const [exampleData3, setExampleData3] = useState('');
  const [exampleData4, setExampleData4] = useState('');
  const [exampleData5, setExampleData5] = useState('');
  const [exampleData6, setExampleData6] = useState('');

  useEffect(() => {
    fetch('/example1.txt')
      .then((res) => res.text())
      .then(setExampleData1)
      .catch((err) => console.error('Error loading example1:', err));

    fetch('/example2.txt')
      .then((res) => res.text())
      .then(setExampleData2)
      .catch((err) => console.error('Error loading example2:', err));

    fetch('/example3.txt')
      .then((res) => res.text())
      .then(setExampleData3)
      .catch((err) => console.error('Error loading example3:', err));

    fetch('/example4.txt')
      .then((res) => res.text())
      .then(setExampleData4)
      .catch((err) => console.error('Error loading example4:', err));

    // Add fetch for example5
    fetch('/example5.txt')
      .then((res) => res.text())
      .then(setExampleData5)
      .catch((err) => console.error('Error loading example5:', err));

      fetch('/example6.txt')
      .then((res) => res.text())
      .then(setExampleData6)
      .catch((err) => console.error('Error loading example5:', err));
  }, []);

  return {
    exampleData1,
    exampleData2,
    exampleData3,
    exampleData4,
    exampleData5, 
    exampleData6
  };
};

export default useExampleData;