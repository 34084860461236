// src/pages/DocsPage.jsx
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const DocsPage = () => {
  const [content, setContent] = useState('');
  useEffect(() => {
    fetch('/docs.md')
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((error) => console.error('Error fetching docs:', error));
  }, []);
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-md">
      <div className="prose prose-lg prose-indigo">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default DocsPage;
