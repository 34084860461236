// src/features/species/pages/Index.tsx

import React, { useState } from "react"
import { Upload } from "lucide-react"
import { Link } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"

import { Card, CardContent } from "../components/ui/card.tsx"
import { Button } from "../components/ui/button.tsx"
import { Input } from "../components/ui/input.tsx"
import { SpeciesCard } from "../components/species/SpeciesCard.tsx"
import { AddSpeciesForm } from "../components/species/AddSpeciesForm.tsx"

const Index = () => {
  // Toggle to show/hide the "Add New Species" form
  const [showNewForm, setShowNewForm] = useState(false)

  // For filtering the fetched species
  const [searchQuery, setSearchQuery] = useState("")

  // 1) Fetch data from your backend using the v5 "object" syntax.
  //    The response is assumed to be:  { species_data: [ { name, imagelink, latin_name }, ... ] }
  const fetchSpecies = async () => {
    const res = await fetch("https://atlaz-api.com/species/get-species")
    if (!res.ok) {
      throw new Error("Error fetching species.")
    }
    return res.json()
  }

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["species"],
    queryFn: fetchSpecies,
  })

  // 2) Handle loading/error states
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <p>Loading species...</p>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <p className="text-red-500">Error: {(error as Error).message}</p>
      </div>
    )
  }

  // 3) Transform the server data to match your UI’s shape
  //    For example, your <SpeciesCard> expects { id, name, latinName, image? }
  //    We generate a pseudo-ID using the index, or adapt if your backend provides an actual ID
  const fetchedSpecies = data?.species_data?.map((sp: any, index: number) => ({
    id: index + 1,         // Or sp.id if your actual backend has an ID
    name: sp.name,
    latinName: sp.latin_name,
    image: sp.imagelink,
    // If your SpeciesCard needs more fields, transform them here
  })) || []

  // 4) Filter the species according to the search input
  const filteredSpecies = fetchedSpecies.filter((species: any) => {
    return (
      species.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      species.latinName?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  })

  // (Optional) If you want newly created species to appear right away after form submission,
  // you can pass a callback to AddSpeciesForm that triggers `queryClient.invalidateQueries(["species"])`.
  // For now, we just toggle the form.

  return (
    <div className="min-h-screen bg-gray-50">
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="space-y-8">
          {/* Header Card */}
          <div className="bg-white shadow rounded-lg p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Welcome to the Species Portal
            </h1>
            <p className="text-lg text-gray-600">
              You can search existing species, view details, or add new ones.
            </p>
          </div>

          {/* Search + Add Species Form */}
          <div className="bg-white shadow rounded-lg p-6">
            <div className="max-w-xl mx-auto mb-6">
              <Input
                type="search"
                placeholder="Search species by name or latin name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full"
              />
            </div>

            <div className="flex justify-center mb-6">
              <Button variant="outline" onClick={() => setShowNewForm(!showNewForm)}>
                {showNewForm ? "Hide Form" : "Add New Species"}
              </Button>
            </div>

            {showNewForm && (
              <AddSpeciesForm />
            )}

            {/* Species Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredSpecies.map((species: any) => (
                <SpeciesCard key={species.id} species={species} />
              ))}
            </div>
          </div>

          {/* Upload Card Link */}
          <Link to="/species/upload">
            <Card className="hover:shadow-lg transition-shadow cursor-pointer">
              <CardContent className="p-6 flex flex-col items-center text-center">
                <Upload className="h-12 w-12 text-primary mb-4" />
                <h2 className="text-xl font-semibold mb-2">Upload Data</h2>
                <p className="text-gray-600">Upload new species data</p>
              </CardContent>
            </Card>
          </Link>
        </div>
      </main>
    </div>
  )
}

export default Index