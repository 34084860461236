import React, { useState, useMemo, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { highlightMultipleMatches } from "../../../../utils/highlightMatches.js";

interface PDFViewerProps {
  markdown: string;
  highlightText?: string | null;
}

export const PDFViewer = ({ markdown, highlightText }: PDFViewerProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);

  // Prepare highlight queries
  const queries = useMemo(() => {
    const queriesArray = [];
    if (highlightText?.trim()) {
      queriesArray.push({
        query: highlightText.trim(),
        color: "#ffe599",
        className: "highlight-source",
      });
    }
    if (searchTerm.trim()) {
      queriesArray.push({
        query: searchTerm.trim(),
        color: "#cfe2f3",
        className: "highlight-search",
      });
    }
    return queriesArray;
  }, [highlightText, searchTerm]);

  // Process the markdown
  const processedMarkdown = useMemo(() => {
    return highlightMultipleMatches(markdown, queries);
  }, [markdown, queries]);

  /**
   * 1) Scroll to the first "highlight-source" if we have highlightText
   *    This triggers whenever highlightText (or the processed highlights) changes
   */
  useEffect(() => {
    if (!highlightText?.trim()) return;

    const container = containerRef.current;
    if (!container) return;

    // Find the first <mark> with class 'highlight-source'
    const firstSourceMark = container.querySelector("mark.highlight-source");
    if (firstSourceMark) {
      const containerRect = container.getBoundingClientRect();
      const markRect = firstSourceMark.getBoundingClientRect();

      const offsetTop = markRect.top - containerRect.top;
      const containerHeight = container.clientHeight;
      const markHeight = markRect.height;
      const scrollPosition =
        offsetTop - containerHeight / 2 + markHeight / 2;

      container.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [highlightText, processedMarkdown]);

  /**
   * 2) Scroll to the first "highlight-search" if we have a search term
   *    This triggers whenever searchTerm (or the processed highlights) changes
   */
  useEffect(() => {
    if (!searchTerm.trim()) return;

    const container = containerRef.current;
    if (!container) return;

    // Find the first <mark> with class 'highlight-search'
    const firstSearchMark = container.querySelector("mark.highlight-search");
    if (firstSearchMark) {
      const containerRect = container.getBoundingClientRect();
      const markRect = firstSearchMark.getBoundingClientRect();

      const offsetTop = markRect.top - containerRect.top;
      const containerHeight = container.clientHeight;
      const markHeight = markRect.height;
      const scrollPosition =
        offsetTop - containerHeight / 2 + markHeight / 2;

      container.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [searchTerm, processedMarkdown]);

  return (
    <div
      className="border rounded bg-gray-50 p-4 max-h-[600px] overflow-auto"
      ref={containerRef}
    >
      {/* Search Input */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search in document..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      {/* Rendered Markdown with Highlights */}
      <ReactMarkdown
        children={processedMarkdown}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          mark: ({ node, ...props }) => <mark {...props} />,
        }}
      />
    </div>
  );
};