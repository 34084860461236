// src/data/mockPDFExtraction.ts

export interface PDFExtraction {
  pdfName: string;
  // Updated extracted fields based on new keys
  fields: {
    Extant_Populations: number;
    Extinct_Populations: number;
    Population_Sizes_Lower_Bound: number;
    Population_Sizes_Upper_Bound: number;
    Population_Less_Than_5000_Individuals: number;
    Phylogenetic_Geographic_Studies: boolean;
    Population_Genetic_Studies: boolean;
    DNA_based_Temporal_Monitoring: boolean;
  };
}

// For simplicity, assume each species has 2-3 PDFs with extracted data
export const mockPDFExtractions: Record<number, PDFExtraction[]> = {
  1: [
    {
      pdfName: "Alkonblavinge_Report_1.pdf",
      fields: {
        Extant_Populations: 12,
        Extinct_Populations: 3,
        Population_Sizes_Lower_Bound: 8000,
        Population_Sizes_Upper_Bound: 12000,
        Population_Less_Than_5000_Individuals: 10,
        Phylogenetic_Geographic_Studies: true,
        Population_Genetic_Studies: true,
        DNA_based_Temporal_Monitoring: false,
      },
    },
    {
      pdfName: "Alkonblavinge_Report_2.pdf",
      fields: {
        Extant_Populations: 10,
        Extinct_Populations: 1,
        Population_Sizes_Lower_Bound: 7000,
        Population_Sizes_Upper_Bound: 9000,
        Population_Less_Than_5000_Individuals: 5,
        Phylogenetic_Geographic_Studies: false,
        Population_Genetic_Studies: true,
        DNA_based_Temporal_Monitoring: true,
      },
    },
  ],
  2: [
    {
      pdfName: "Klockgentiana_Study.pdf",
      fields: {
        Extant_Populations: 5,
        Extinct_Populations: 0,
        Population_Sizes_Lower_Bound: 3500,
        Population_Sizes_Upper_Bound: 4500,
        Population_Less_Than_5000_Individuals: 4,
        Phylogenetic_Geographic_Studies: true,
        Population_Genetic_Studies: false,
        DNA_based_Temporal_Monitoring: false,
      },
    },
  ],
  // ... Add more species as needed
};