import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const CodeSignUpPage = () => {
  const [email, setEmail] = useState('');
  const [willingnessToPay, setWillingnessToPay] = useState('');
  const [message, setMessage] = useState('');

  const handleSignUp = (e) => {
    e.preventDefault();

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      { 
        from_name: email,
        user_email: email,
        message: `User signed up for pre-access to Atlaz Code - pro version. Willingness to pay: ${willingnessToPay}`
      },
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    )
    .then((response) => {
       console.log('SUCCESS!', response.status, response.text);
       setMessage('Request received. We will be in touch soon!');
       setEmail('');
       setWillingnessToPay('');
    }, (err) => {
       console.error('FAILED...', err);
       setMessage('Failed to send request.');
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-4 bg-gray-50">
      <div className="max-w-sm w-full bg-white p-8 rounded-md shadow-md">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800 text-center">Atlaz Code Pro Access</h2>
        <p className="text-gray-600 mb-4 text-center">
          Sign up for early access to Atlaz Code - Pro Version. Powered by agents & Knowledge Graphs to produce code straight into your codebase! Power your code generation with o1 through this tool. The first pro users will get unlimited o1 access!
        </p>
        <form onSubmit={handleSignUp} className="space-y-5">
          <div>
            <label className="block mb-1 font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#A9C47C]"
              placeholder="you@example.com"
              required
            />
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-700">Willingness to Pay</label>
            <input
              type="text"
              value={willingnessToPay}
              onChange={(e) => setWillingnessToPay(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#A9C47C]"
              placeholder="Amount in USD"
            />
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-[#A9C47C] text-white font-semibold rounded-md hover:bg-[#8DA25F] transition"
          >
            Sign Up
          </button>
        </form>
        {message && <div className="mt-4 text-center text-gray-500">{message}</div>}
      </div>
    </div>
  );
};

export default CodeSignUpPage;