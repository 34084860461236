import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { auth } from '../utils/firebase';
import { cachedExamples } from '../cached_examples';
import Button from '../components/common/Button';
import CurveView from '../components/graph/CurveView';
import { highlightMatches } from '../utils/highlightMatches';
import useExampleData from '../hooks/useExampleData';

const BuilderPage = () => {
  // States
  const [inputText, setInputText] = useState('');
  const [customizationText, setCustomizationText] = useState('');
  const [graphData, setGraphData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState('');
  const [selectedElementData, setSelectedElementData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  // Example data
  const {
    exampleData1,
    exampleData2,
    exampleData3,
    exampleData4,
    exampleData5,
    exampleData6
  } = useExampleData();

  // Handle examples
  const handleExampleClick = (exampleText) => {
    setInputText(exampleText);
  };

  // Generate Graph
  const handleSubmit = async () => {
    if (!inputText.trim()) {
      toast.error('Please enter some source data');
      return;
    }
    // Check cached
    if (inputText.trim() === exampleData1.trim()) {
      setGraphData(cachedExamples.example1);
      setStatus('Loaded from cache');
      return;
    }
    if (inputText.trim() === exampleData2.trim()) {
      setGraphData(cachedExamples.example2);
      setStatus('Loaded from cache');
      return;
    }
    if (inputText.trim() === exampleData3.trim()) {
      setGraphData(cachedExamples.example3);
      setStatus('Loaded from cache');
      return;
    }
    if (inputText.trim() === exampleData4.trim()) {
      setGraphData(cachedExamples.example4);
      setStatus('Loaded from cache');
      return;
    }
    if (inputText.trim() === exampleData5.trim()) {
      setGraphData(cachedExamples.example5);
      setStatus('Loaded from cache');
      return;
    }
    if (inputText.trim() === exampleData6.trim()) {
      setGraphData(cachedExamples.example8);
      setStatus('Loaded from cache');
      return;
    }

    setIsProcessing(true);
    setStatus('Processing...');
    setGraphData(null);

    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch('https://atlaz-api.com/raspberry-preview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ text: inputText, customization: customizationText }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop();

        for (const line of lines) {
          if (line.trim()) {
            try {
              const chunk = JSON.parse(line);
              if (chunk.status === 'still_processing') {
                setStatus('Still processing...');
              } else if (chunk.status === 'completed' && chunk.graph) {
                const { nodes, edges, categories } = chunk.graph;
                setGraphData({
                  nodes: nodes || [],
                  edges: edges || [],
                  categories: categories || [],
                });
                setStatus('Completed');
              }
            } catch (err) {
              console.error('Error parsing JSON chunk:', err, 'Line:', line);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to generate graph');
      setStatus('Error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  // For "Selected Element Details"
  const label = selectedElementData?.label || 'No element selected';
  const info = selectedElementData?.info || 'Select a node or edge to view details';

  // For the highlight
  const highlightedHTML = highlightMatches(inputText, searchTerm);

  return (
    // Make the entire page scrollable if needed
    <div className="min-h-screen w-full overflow-y-auto p-4">
      {/* 2-column layout. We fix the total height so they align. */}
      <div className="max-w-7xl mx-auto flex gap-6">
        {/* LEFT column (fixed total height ~ 800px) */}
        <div
          className="w-1/2 border rounded p-4 flex flex-col gap-4"
          style={{ height: '1020px' }}
        >
          {/* Title & Description */}
          <div>
            <h1 className="text-2xl font-bold">Build your own knowledge graph</h1>
            <p className="text-gray-700 leading-relaxed">
            Enter your source data and optional customization instructions below. I recommend starting without customization. Then click "Generate Graph" to create a Knowledge Graph. 
          Be patient—this can take up to 10 minutes due to current rate limits. Please report any bugs to rasmus@atlaz.ai.
          It should be able to process up to 40 pages of text thus far (15 pages for dense math). 
            </p>
          </div>

          {/* Examples */}
          <div>
            <h2 className="text-lg font-semibold mb-2">Examples</h2>
            <div className="flex flex-wrap gap-2">
              <div
                className="border border-gray-300 px-3 py-1 rounded cursor-pointer hover:bg-gray-100"
                onClick={() => handleExampleClick(exampleData1)}
              >
                <strong>Tesla Q.Report</strong>
              </div>
              <div
                className="border border-gray-300 px-3 py-1 rounded cursor-pointer hover:bg-gray-100"
                onClick={() => handleExampleClick(exampleData2)}
              >
                <strong>Abstract Algebra</strong>
              </div>
              <div
                className="border border-gray-300 px-3 py-1 rounded cursor-pointer hover:bg-gray-100"
                onClick={() => handleExampleClick(exampleData3)}
              >
                <strong>CRISPR-Cas9</strong>
              </div>
              <div
                className="border border-gray-300 px-3 py-1 rounded cursor-pointer hover:bg-gray-100"
                onClick={() => handleExampleClick(exampleData4)}
              >
                <strong>Attention</strong>
              </div>
              {/*
               <div
                className="border border-gray-300 px-3 py-1 rounded cursor-pointer hover:bg-gray-100"
                onClick={() => handleExampleClick(exampleData5)}
              >
               <strong>Customer Support</strong>
              </div>
               */}
              <div
                className="border border-gray-300 px-3 py-1 rounded cursor-pointer hover:bg-gray-100"
                onClick={() => handleExampleClick(exampleData4)}
              >
                <strong>Attention</strong>
                
              </div>
            </div>
          </div>
          {/* Customization */}
          <div>
            <h2 className="text-lg font-semibold">Customization</h2>
            <textarea
              className="w-full border border-gray-300 rounded p-2 h-20 overflow-auto"
              value={customizationText}
              onChange={(e) => setCustomizationText(e.target.value)}
              disabled={isProcessing}
              placeholder="Enter customization..."
            />
          </div>

          {/* Button + status */}
          <div className="flex items-center justify-between">
            <Button
              onClick={handleSubmit}
              disabled={isProcessing}
              variant="success"
            >
              {isProcessing ? 'Processing...' : 'Generate Graph'}
            </Button>
            <span className="text-gray-600">{status}</span>
          </div>

          {/* Selected Element Details */}
          {graphData && (
            <div className="border border-gray-300 p-2 rounded bg-white">
              <h2 className="text-lg font-semibold mb-1">Selected Element Details</h2>
              <p><strong>Name:</strong> {label}</p>
              <p><strong>Info:</strong> {info}</p>
            </div>
          )}

          {/* Graph (made a bit taller than previous to align with right side) */}
          {graphData && (
            <div className="border border-gray-300 rounded p-2 bg-white" style={{ height: '425px' }}>
              <h2 className="text-lg font-semibold mb-2">Generated Graph</h2>
              <div className="h-full">
                <CurveView
                  graphData={graphData}
                  onElementSelect={(d) => setSelectedElementData(d)}
                />
              </div>
            </div>
          )}
        </div>

        {/* RIGHT column (also ~800px total). 
            We'll make the text region quite tall to align bottom with the left's 460px graph. 
        */}
        

        <div
          className="w-1/2 border rounded p-4 flex flex-col gap-4"
          style={{ height: '1020px' }}
        >
          {/* Source Data (about half the original height) */}
        <div>
            <h2 className="text-lg font-semibold">Source Data</h2>
            <textarea
              className="w-full border border-gray-300 rounded p-2 h-16 overflow-auto"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              disabled={isProcessing}
              placeholder="Enter source data..."
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold">Search in Source Data</h2>
            <input
              type="text"
              className="border border-gray-300 rounded p-2 w-full"
              placeholder="Type a term to find..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {/* 
            We'll make this tall enough to match the bottom of the left panel's graph.
            The user wants no scrollbar, so we use overflow-hidden or clip the text. 
          */}
          <div
            className="border border-gray-300 rounded p-2 text-sm text-gray-800 overflow-auto"
            style={{ height: '784px' }}
            dangerouslySetInnerHTML={{ __html: highlightedHTML }}
          />
        </div>
      </div>
    </div>
  );
};

export default BuilderPage;
