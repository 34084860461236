import React, { Component, ReactNode } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"

/**
 * A class-based Error Boundary that catches *render-time* errors
 * in React components.
 */
class SpeciesErrorBoundary extends Component<{
  onError: (error: Error, errorInfo: React.ErrorInfo) => void
  children?: ReactNode
}> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Let the parent or the wrapper handle the actual redirect or logging
    this.props.onError(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // We render nothing here because the parent might handle redirect
      return null
    }
    return this.props.children
  }
}
/*
 * A small wrapper that uses `useNavigate` so that we can programmatically
 * navigate when an error is caught.
 */
export function SpeciesErrorBoundaryWrapper({ children }: { children?: ReactNode }) {
  const navigate: NavigateFunction = useNavigate()

  const handleError = (error: Error) => {
    console.error("[SpeciesErrorBoundary caught an error]", error)
    // Force navigation to /species
    navigate("/species")
  }

  return (
    <SpeciesErrorBoundary onError={handleError}>
      {children}
    </SpeciesErrorBoundary>
  )
}