import { Check, FileText, ListChecks } from "lucide-react"
import { Separator } from "../ui/separator.tsx"

interface StepIndicatorProps {
  currentStep: "upload" | "check" | "confirmation"
}

export const StepIndicator = ({ currentStep }: StepIndicatorProps) => {
  const steps = [
    { id: "upload", name: "Upload", icon: FileText },
    { id: "check", name: "Double Check", icon: ListChecks },
    { id: "confirmation", name: "Confirmation", icon: Check },
  ]

  return (
    <nav aria-label="Progress" className="px-4 sm:px-0">
      <div className="space-y-2">
        <ol className="flex items-center justify-center">
          {steps.map((step) => (
            <li key={step.id} className="w-24 sm:w-32 text-center">
              <span className={`text-sm font-medium ${
                currentStep === step.id ? "text-primary" : "text-gray-500"
              }`}>
                {step.name}
              </span>
            </li>
          ))}
        </ol>
        
        <ol className="flex items-center justify-center">
          {steps.map((step, stepIdx) => {
            const Icon = step.icon
            return (
              <li key={step.id} className="relative flex items-center">
                <div className="flex items-center justify-center w-24 sm:w-32">
                  <span
                    className={`flex h-10 w-10 items-center justify-center rounded-full border-2 ${
                      currentStep === step.id
                        ? "border-primary bg-primary text-white"
                        : "border-secondary bg-white"
                    }`}
                  >
                    <Icon className="h-5 w-5" />
                  </span>
                </div>
                {stepIdx !== steps.length - 1 && (
                  <div className="w-0">
                    <Separator className="w-full" />
                  </div>
                )}
              </li>
            )
          })}
        </ol>
      </div>
    </nav>
  )
}