// src/App.jsx
import React from 'react';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './utils/firebase';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
} from 'react-router-dom';

import BuilderPage from './pages/BuilderPage';
import DocsPage from './pages/DocsPage';
import Home from './pages/Home';
import About from './pages/About';
import LoginPage from './pages/LoginPage';
import ExpBuilderPage from './pages/ExpBuilderPage';
import ManageAccount from './pages/ManageAccount';
import CodeSignUpPage from './pages/CodeSignUpPage';

// 1) Import your new SpeciesLoginPage
import SpeciesLoginPage from './pages/SpeciesLoginPage';
import SpeciesPortal from './features/species/SpeciesPortal'; // Where /species/* routes live

// Existing ProtectedRoute: these pages redirect to /login
const ProtectedRoute = ({ user, children }) => {
  return user ? children : <Navigate to="/login" replace />;
};

// 2) New ProtectedSpeciesRoute: these pages redirect to /species-login
const ProtectedSpeciesRoute = ({ user, children }) => {
  return user ? children : <Navigate to="/species-login" replace />;
};

const AppContent = () => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        Loading ...
      </div>
    );
  }

  const linkBaseClasses = "px-4 py-2 rounded-md text-sm font-medium transition";
  const activeClasses = "bg-white text-gray-700";
  const defaultClasses = "bg-gray-200 text-gray-700 hover:bg-white";

  const isActive = (path) => location.pathname === path;

  // Decide whether to hide the header:
  const hideHeader = location.pathname.startsWith('/species');

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header: Hide if route starts with /species */}
      {!hideHeader && (
        <header className="bg-gray-200 shadow shrink-0">
          <div className="w-full px-6 py-4 flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/home">
                <img
                  src="/logo.png"
                  alt="Logo"
                  className="h-auto w-auto mr-3 mix-blend-multiply"
                  style={{ maxHeight: '40px' }}
                />
              </Link>
            </div>
            <nav className="flex space-x-4 items-center">
              {user && (
                <>
                  <Link
                    to="/builder"
                    className={`${linkBaseClasses} ${
                      isActive('/builder') ? activeClasses : defaultClasses
                    }`}
                  >
                    Knowledge Graph Builder
                  </Link>
                  <Link
                    to="/exp-builder"
                    className={`${linkBaseClasses} ${
                      isActive('/exp-builder') ? activeClasses : defaultClasses
                    }`}
                  >
                    Graph Chat
                  </Link>
                </>
              )}
              <Link
                to="/docs"
                className={`${linkBaseClasses} ${
                  isActive('/docs') ? activeClasses : defaultClasses
                }`}
              >
                Docs
              </Link>
              <Link
                to="/about"
                className={`${linkBaseClasses} ${
                  isActive('/about') ? activeClasses : defaultClasses
                }`}
              >
                About
              </Link>
              <Link
                to="/code-signup"
                className={`${linkBaseClasses} ${
                  isActive('/code-signup') ? activeClasses : defaultClasses
                }`}
              >
                Code Access
              </Link>
              {user ? (
                <>
                  <Link
                    to="/manage-account"
                    className={`${linkBaseClasses} ${
                      isActive('/manage-account')
                        ? activeClasses
                        : defaultClasses
                    }`}
                  >
                    Manage Account
                  </Link>
                  <button
                    onClick={() => auth.signOut()}
                    className={`${linkBaseClasses} ${defaultClasses}`}
                  >
                    Logout
                  </button>
                </>
              ) : (
                <Link
                  to="/login"
                  className={`${linkBaseClasses} ${
                    isActive('/login') ? activeClasses : defaultClasses
                  }`}
                >
                  Login
                </Link>
              )}
            </nav>
          </div>
        </header>
      )}

      <main className="flex-grow">
        <Routes>
          {/* Standard routes */}
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/docs" element={<DocsPage />} />
          <Route
            path="/login"
            element={user ? <Navigate to="/builder" replace /> : <LoginPage />}
          />
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/code-signup" element={<CodeSignUpPage />} />

          {/* Protected route for standard pages (redirect to /login) */}
          <Route
            path="/manage-account"
            element={
              <ProtectedRoute user={user}>
                <ManageAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/builder"
            element={
              <ProtectedRoute user={user}>
                <BuilderPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/exp-builder"
            element={
              <ProtectedRoute user={user}>
                <ExpBuilderPage />
              </ProtectedRoute>
            }
          />

          {/* 3) New: separate login & protected route for the /species area */}
          <Route
            path="/species-login"
            element={user ? <Navigate to="/species" replace /> : <SpeciesLoginPage />}
          />
          <Route
            path="/species/*"
            element={
              <ProtectedSpeciesRoute user={user}>
                <SpeciesPortal />
              </ProtectedSpeciesRoute>
            }
          />

          {/* If you previously had a /upload route, remove or comment it out. */}
          {/* <Route path="/upload" element={<Upload />} /> */}

          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </main>
      {!hideHeader && (
        <footer className="bg-gray-200 shadow shrink-0">
          <div className="w-full px-6 py-4 text-center text-gray-500 text-sm">
            &copy; {new Date().getFullYear()} Atlaz. All rights reserved.
          </div>
        </footer>
      )}

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar closeOnClick />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;