// src/features/species/components/species/SpeciesSelect.tsx

import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Check, ChevronsUpDown, Loader2 } from "lucide-react"

import { cn } from "../../lib/utils.ts"
import { Button } from "../ui/button.tsx"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command.tsx"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover.tsx"

/**
 * The parent can pass in `onSelect(idOrName: string)` to know which species
 * got picked from the dropdown. We’ll fetch from the backend, and display the species
 * in a "command" popover with filterable search.
 */
interface SpeciesSelectProps {
  onSelect?: (value: string) => void
}

export function SpeciesSelect({ onSelect }: SpeciesSelectProps) {
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState("")

  // 1) Fetch from your backend, same as in Index.tsx
  const fetchSpecies = async () => {
    const res = await fetch("https://atlaz-api.com/species/get-species")
    if (!res.ok) {
      throw new Error("Error fetching species.")
    }
    return res.json() // => { species_data: [ { name, latin_name, imagelink }, ... ] }
  }

  // v5 "object" syntax for useQuery
  const {
    data,
    isLoading,
    isError,
    error
  } = useQuery({
    queryKey: ["allSpeciesForSelect"],
    queryFn: fetchSpecies,
  })

  // 2) Handle loading/error states
  if (isLoading) {
    return (
      <div className="py-2 px-3 flex items-center space-x-2 text-gray-600">
        <Loader2 className="h-4 w-4 animate-spin" />
        <span>Loading species list...</span>
      </div>
    )
  }

  if (isError) {
    return (
      <p className="text-red-500">
        Error: {(error as Error).message}
      </p>
    )
  }

  // 3) Transform the server data into { value, label } for the command
  //    Using the species index or name as a fallback ID; 
  //    If your backend eventually provides an actual unique ID, prefer that.
  const fetchedSpecies = data?.species_data ?? []
  const speciesOptions = fetchedSpecies.map((sp: any, index: number) => ({
    value: String(sp.id ?? index + 1),  // e.g. "4" or "1", or fallback to index
    label: sp.name || "Unnamed Species",
    raw: sp, // keep the original object, if you want to pass more data
  }))

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedValue
            ? speciesOptions.find((opt) => opt.value === selectedValue)?.label
            : "Select species..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-[200px] p-0 bg-white shadow-md" align="start">
        <Command>
          {/* This built-in filter handles searching within the CommandItems */}
          <CommandInput placeholder="Search species..." />
          <CommandList>
            <CommandEmpty>No species found.</CommandEmpty>
            <CommandGroup>
              {speciesOptions.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.label} // used for searching
                  onSelect={() => {
                    // Toggling the same item -> clear selection
                    const newValue = (item.value === selectedValue) ? "" : item.value
                    setSelectedValue(newValue)
                    // If user provided onSelect, pass them the ID or name
                    if (onSelect) {
                      onSelect(newValue)
                    }
                    setOpen(false)
                  }}
                >
                  {/* Check icon for the selected item */}
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedValue === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}