// src/features/species/components/upload/ReviewForm.tsx

import React, { useState } from "react"
import { Button } from "../ui/button.tsx"
import { Edit, Save, Info, Trash } from "lucide-react"
import { PDFViewer } from "../features/PDFViewer.tsx"

// 1) Import your API service function (instead of calling fetch directly)
import { saveExtractedData } from "../../lib/api.ts"

interface TableRowData {
  Name: string
  DataPoint: number | boolean | null  // <--- allow null in local state
  Source: string
  Motivation: string
}

interface FinalChunk {
  data?: TableRowData[]
  markdown?: string
  pdf_key?: string
  status?: string
}

interface ReviewFormProps {
  extractedData: FinalChunk | null
  selectedFile: File
  speciesName: string  // from your parent
  onConfirm: () => void
}

// Mapping of raw "Name" keys to user-friendly labels
const nameMapping: Record<string, string> = {
  Extant_Populations: "Extant Populations",
  Extinct_Populations: "Extinct Populations",
  Population_Sizes_Lower_Bound: "Population Sizes (Lower Bound)",
  Population_Sizes_Upper_Bound: "Population Sizes (Upper Bound)",
  Population_Less_Than_5000_Individuals: "Populations - Less Than 5000 Individuals",
  Population_More_Than_5000_Individuals: "Populations - More Than 5000 Individuals",
  Phylogenetic_Geographic_Studies: "Phylogenetic/Geographic Studies",
  Population_Genetic_Studies: "Population Genetic Studies",
  DNA_based_Temporal_Monitoring: "DNA-based Temporal Monitoring",
  // Add more mappings as needed
}

export const ReviewForm = ({
  extractedData,
  selectedFile,
  speciesName,
  onConfirm,
}: ReviewFormProps) => {
  // 1) Safely ensure `data` is an array (or empty).
  const safeData = Array.isArray(extractedData?.data) ? extractedData.data : []
  // 2) Markdown for PDFViewer
  const safeMarkdown = extractedData?.markdown ?? ""

  // Table state for user-edited data
  const [tableData, setTableData] = useState<TableRowData[]>(safeData)
  // Track which rows are in edit mode
  const [editIndexes, setEditIndexes] = useState<Record<number, boolean>>({})
  // For showing source info
  const [sourceIndex, setSourceIndex] = useState<number | null>(null)
  // For highlighting in PDFViewer
  const [selectedSourceText, setSelectedSourceText] = useState<string | null>(null)

  console.log("[ReviewForm] extractedData:", extractedData)

  /**
   * Helper to find a row by .Name
   */
  function getDataPointByName(name: string): number | boolean | null {
    const row = tableData.find((r) => r.Name === name)
    return row ? row.DataPoint : null
  }

  /**
   * Convert the user-edited table data into the final payload.
   * If a row is missing or empty => pass null for that field.
   */
  async function handleSaveExtractedData() {
    try {
      const pdfKey = extractedData?.pdf_key ?? ""

      // Build each numeric or boolean field:
      // If we find no row, or the user set an empty field, we pass null.
      const Extant_Populations = parseNumberOrNull(
        getDataPointByName("Extant_Populations")
      )
      const Extinct_Populations = parseNumberOrNull(
        getDataPointByName("Extinct_Populations")
      )
      const Population_Sizes_Lower_Bound = parseNumberOrNull(
        getDataPointByName("Population_Sizes_Lower_Bound")
      )
      const Population_Sizes_Upper_Bound = parseNumberOrNull(
        getDataPointByName("Population_Sizes_Upper_Bound")
      )
      const Population_Less_Than_5000_Individuals = parseNumberOrNull(
        getDataPointByName("Population_Less_Than_5000_Individuals")
      )
      const Population_More_Than_5000_Individuals = parseNumberOrNull(
        getDataPointByName("Population_More_Than_5000_Individuals")
      )

      const Phylogenetic_Geographic_Studies = parseBoolOrNull(
        getDataPointByName("Phylogenetic/Geographic_Studies")
      )
      const Population_Genetic_Studies = parseBoolOrNull(
        getDataPointByName("Population_Genetic_Studies")
      )
      const DNA_based_Temporal_Monitoring = parseBoolOrNull(
        getDataPointByName("DNA_based_Temporal_Monitoring")
      )

      const payload = {
        pdf_key: pdfKey,
        species_name: speciesName,

        Extant_Populations,
        Extinct_Populations,
        Population_Sizes_Lower_Bound,
        Population_Sizes_Upper_Bound,
        Population_Less_Than_5000_Individuals,
        Population_More_Than_5000_Individuals,

        Phylogenetic_Geographic_Studies,
        Population_Genetic_Studies,
        DNA_based_Temporal_Monitoring,
      }

      console.log("[ReviewForm] about to save =>", payload)
      // Call the API service function instead of direct fetch
      const result = await saveExtractedData(payload)
      console.log("[ReviewForm] Save success:", result)

      // Move on to the confirmation step
      onConfirm()
    } catch (err) {
      console.error("[ReviewForm] handleSaveExtractedData error:", err)
      alert("Failed to save data. See console for details.")
    }
  }

  /**
   * Convert a row’s value to a numeric (or null).
   * If the user typed an empty string or it's boolean => returns null.
   */
  function parseNumberOrNull(val: number | boolean | null): number | null {
    if (val === null) return null
    if (typeof val === "boolean") return null
    // Already a number => keep it
    return val
  }

  /**
   * Convert a row’s value to a boolean (or null).
   * If the user typed an empty string or it's numeric => returns null.
   */
  function parseBoolOrNull(val: number | boolean | null): boolean | null {
    if (val === null) return null
    if (typeof val === "number") return null
    // Already boolean => keep it
    return val
  }

  /**
   * Called when user changes a DataPoint in the table (either boolean or number)
   */
  const handleDataPointChange = (rowIndex: number, newValue: string) => {
    setTableData((prev) => {
      const updated = [...prev]
      const currentValue = updated[rowIndex].DataPoint

      // If we consider this row to represent a boolean...
      if (typeof currentValue === "boolean" || currentValue == null) {
        // For booleans, interpret "yes" -> true, "no" -> false, empty -> null
        if (newValue.trim() === "") {
          updated[rowIndex].DataPoint = null
        } else {
          updated[rowIndex].DataPoint = newValue.toLowerCase() === "yes"
        }
      } else {
        // Otherwise it's numeric
        if (newValue.trim() === "") {
          // If user clears the input, store null
          updated[rowIndex].DataPoint = null
        } else {
          const numericValue = Number(newValue)
          updated[rowIndex].DataPoint = isNaN(numericValue) ? null : numericValue
        }
      }
      return updated
    })
  }

  // Toggle edit mode
  const handleEditClick = (rowIndex: number) => {
    setEditIndexes((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }))
  }

  // Show/hide the Source for a row (and highlight in PDF)
  const handleInfoClick = (rowIndex: number) => {
    if (sourceIndex === rowIndex) {
      setSourceIndex(null)
      setSelectedSourceText(null)
    } else {
      setSourceIndex(rowIndex)
      setSelectedSourceText(tableData[rowIndex].Source)
    }
  }

  // Delete a row from the table
  const handleDeleteClick = (rowIndex: number) => {
    setTableData((prev) => prev.filter((_, idx) => idx !== rowIndex))

    if (sourceIndex === rowIndex) {
      setSourceIndex(null)
      setSelectedSourceText(null)
    } else if (sourceIndex !== null && rowIndex < sourceIndex) {
      setSourceIndex((old) => (old != null ? old - 1 : null))
    }

    setEditIndexes((prev) => {
      const updated = { ...prev }
      delete updated[rowIndex]
      // Shift the keys
      const newEdit: Record<number, boolean> = {}
      Object.keys(updated).forEach((k) => {
        const idx = Number(k)
        newEdit[idx > rowIndex ? idx - 1 : idx] = updated[k]
      })
      return newEdit
    })
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        
        {/* LEFT SIDE: The Extracted Data Table */}
        <div>
          <h2 className="text-lg font-medium mb-4">Review Extracted Data</h2>
          {tableData.length > 0 ? (
            <table className="w-full text-sm border-collapse">
              <thead>
                <tr className="bg-gray-50 border-b">
                  <th className="p-2 text-left">Name</th>
                  <th className="p-2 text-left">DataPoint</th>
                  <th className="p-2 text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIdx) => {
                  const isEditing = !!editIndexes[rowIdx]
                  const isBoolean = typeof row.DataPoint === "boolean"
                  const value = row.DataPoint

                  return (
                    <tr
                      key={rowIdx}
                      className={`border-b hover:bg-gray-50 ${
                        sourceIndex === rowIdx ? "bg-yellow-100" : ""
                      }`}
                    >
                      {/* Name Cell */}
                      <td className="p-2">
                        {nameMapping[row.Name] || row.Name}
                      </td>

                      {/* DataPoint Cell */}
                      <td className="p-2">
                        {isEditing ? (
                          isBoolean ? (
                            // Boolean => yes/no/null
                            <select
                              value={value === true ? "yes" : value === false ? "no" : ""}
                              onChange={(e) => handleDataPointChange(rowIdx, e.target.value)}
                              className="border p-1 rounded"
                            >
                              <option value="">(none)</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          ) : (
                            // Numeric => Number Input or null
                            <input
                              type="number"
                              value={value ?? ""}
                              onChange={(e) => handleDataPointChange(rowIdx, e.target.value)}
                              className="w-full p-1 border rounded"
                            />
                          )
                        ) : (
                          // Display mode
                          isBoolean 
                            ? (value === true ? "Yes" : value === false ? "No" : "—")
                            : (value ?? "—") // null => display "—"
                        )}
                      </td>

                      {/* Actions Cell */}
                      <td className="p-2 text-right flex justify-end">
                        <button
                          onClick={() => handleEditClick(rowIdx)}
                          className="mr-2 text-gray-600 hover:text-gray-900"
                          aria-label={isEditing ? "Save" : "Edit"}
                        >
                          {isEditing ? <Save className="h-4 w-4" /> : <Edit className="h-4 w-4" />}
                        </button>
                        <button
                          onClick={() => handleInfoClick(rowIdx)}
                          className="mr-2 text-gray-600 hover:text-gray-900"
                          aria-label="Info"
                        >
                          <Info className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(rowIdx)}
                          className="text-red-600 hover:text-red-800"
                          aria-label="Delete"
                        >
                          <Trash className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-600 italic">No rows found in the extraction.</p>
          )}

          {/* Source & Motivation Preview (if any row is selected) */}
          {sourceIndex !== null && tableData[sourceIndex] && (
            <div className="mt-4 p-4 border rounded bg-gray-100">
              <h4 className="text-md font-semibold mb-2">
                Motivation for "
                {nameMapping[tableData[sourceIndex].Name] ||
                  tableData[sourceIndex].Name}
                "
              </h4>
              <p className="text-gray-700 mb-4">
                {tableData[sourceIndex].Motivation || "(No motivation text)"}
              </p>

              <h4 className="text-md font-semibold mb-2">
                Source for "
                {nameMapping[tableData[sourceIndex].Name] ||
                  tableData[sourceIndex].Name}
                "
              </h4>
              <p className="text-gray-700">
                {tableData[sourceIndex].Source || "(No source text)"}
              </p>
            </div>
          )}
        </div>

        {/* RIGHT SIDE: PDF Viewer */}
        <div>
          <h3 className="text-lg font-medium mb-4">PDF Preview</h3>
          <PDFViewer markdown={safeMarkdown} highlightText={selectedSourceText} />
        </div>
      </div>

      {/* Confirm & Save Button */}
      <div className="flex justify-end mt-6">
        <Button
          onClick={handleSaveExtractedData}
          // Prevent saving if any row is still in edit mode
          disabled={Object.values(editIndexes).some(Boolean)}
        >
          Confirm Data
        </Button>
      </div>
    </div>
  )
}
