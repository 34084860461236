// src/features/species/pages/Upload.tsx

import React, { useState } from "react"
import { toast } from "sonner"
import { StepIndicator } from "../components/upload/StepIndicator.tsx"
import { UploadForm } from "../components/upload/UploadForm.tsx"
import { ReviewForm } from "../components/upload/ReviewForm.tsx"
import { ConfirmationMessage } from "../components/upload/ConfirmationMessage.tsx"

interface FinalChunk {
  data?: Array<{
    Name: string
    DataPoint: number | boolean
    Source: string
    Motivation: string
  }>
  markdown?: string
  pdf_key?: string
  status?: string
}

type Step = "upload" | "check" | "confirmation"

export default function UploadPage() {
  const [currentStep, setCurrentStep] = useState<Step>("upload")
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [extractedData, setExtractedData] = useState<FinalChunk | null>(null)
  const [streamStatus, setStreamStatus] = useState("")
  const [speciesName, setSpeciesName] = useState<string>("")

  // Track whether the extraction is in progress (show spinner)
  const [isExtracting, setIsExtracting] = useState(false)

  console.log("[UploadPage] render. step =", currentStep, "extractedData =", extractedData)

  /**
   * 1) Fetch a presigned URL from the backend
   * 2) Upload the selected file directly to S3
   * 3) Return the S3 key if successful
   */
  async function handleDirectS3Upload(file: File): Promise<string> {
    // 1) Ask your backend for a presigned URL
    const filename = encodeURIComponent(file.name)
    const presignedUrl = `https://atlaz-api.com/species/api/generate-presigned-url?filename=${filename}`
    console.log("[handleDirectS3Upload] Requesting presigned URL from:", presignedUrl)

    const presignedResp = await fetch(presignedUrl)
    console.log("[handleDirectS3Upload] Presigned response status:", presignedResp.status, presignedResp.statusText)
    if (!presignedResp.ok) {
      const errorText = await presignedResp.text()
      console.error("[handleDirectS3Upload] Failed to get presigned URL. Response text:", errorText)
      throw new Error("Failed to get presigned URL")
    }

    const { url, fields, key } = await presignedResp.json()
    console.log("[handleDirectS3Upload] Presigned JSON:", { url, fields, key })

    // 2) Build the FormData that S3 expects
    const formData = new FormData()
    Object.entries(fields).forEach(([fieldName, fieldValue]) => {
      formData.append(fieldName, fieldValue as string)
    })
    formData.append("file", file)

    console.log("[handleDirectS3Upload] Uploading file to S3 presigned URL...")
    // 3) POST directly to the S3 presigned URL
    const uploadRes = await fetch(url, {
      method: "POST",
      body: formData,
    })
    console.log("[handleDirectS3Upload] S3 upload response status:", uploadRes.status, uploadRes.statusText)
    if (!uploadRes.ok) {
      const errorText = await uploadRes.text()
      console.error("[handleDirectS3Upload] S3 upload failed. Response text:", errorText)
      throw new Error("S3 upload failed with status " + uploadRes.status)
    }

    console.log("[handleDirectS3Upload] S3 upload successful. Returning key:", key)
    // 'key' is the path in S3 we can use for extraction
    return key
  }

  /**
   * Trigger the PDF extraction process:
   * 1) Upload the file to S3
   * 2) Call the streaming extraction endpoint with the pdf_key + speciesName
   */
  async function handleExtractData() {
    if (!selectedFile) {
      toast.error("No PDF file selected!")
      return
    }
    if (!speciesName) {
      toast.error("No species selected!")
      return
    }

    setIsExtracting(true)
    setStreamStatus("Starting extraction...")

    try {
      // STEP 1: Direct upload to S3
      console.log("[handleExtractData] Step 1: Upload file to S3.")
      const pdfKey = await handleDirectS3Upload(selectedFile)
      console.log("[handleExtractData] Received pdfKey from S3:", pdfKey)

      // STEP 2: Now stream extraction from the server, passing only the key
      console.log("[handleExtractData] Step 2: Begin streaming extraction. speciesName =", speciesName)
      const extractionForm = new FormData()
      extractionForm.append("pdf_key", pdfKey)
      extractionForm.append("species_name", speciesName)

      console.log("[handleExtractData] Sending POST to /species/api/stream-extract-pdf-progress-key with form data:")
      extractionForm.forEach((val, key) => {
        console.log("   ", key, "=", val)
      })

      const response = await fetch(
        "https://atlaz-api.com/species/api/stream-extract-pdf-progress-key",
        {
          method: "POST",
          body: extractionForm,
        }
      )
      console.log("[handleExtractData] Extraction endpoint response status:", response.status, response.statusText)

      if (!response.ok) {
        const errText = await response.text()
        console.error("[handleExtractData] Extraction endpoint returned error text:", errText)
        const errMsg = `Error: HTTP ${response.status} - ${response.statusText}`
        throw new Error(errMsg)
      }

      const reader = response.body?.getReader()
      if (!reader) {
        throw new Error("ReadableStream not supported or no body returned.")
      }

      const decoder = new TextDecoder()
      let buffer = ""

      while (true) {
        const { value, done } = await reader.read()
        if (done) {
          console.log("[handleExtractData] Stream completed.")
          break
        }
        const textChunk = decoder.decode(value, { stream: true })
        buffer += textChunk

        // Split lines by newline. The last element remains in 'buffer' if incomplete.
        let lines = buffer.split("\n")
        buffer = lines.pop() || ""

        for (const line of lines) {
          const trimmed = line.trim()
          if (!trimmed) continue

          console.log("[handleExtractData] Raw line from server:", trimmed)
          try {
            const chunk = JSON.parse(trimmed)
            console.log("[handleExtractData] Parsed chunk:", chunk)

            if (chunk.status === "still_processing") {
              // Optionally update streamStatus or a progress bar
              setStreamStatus("Extraction still in progress...")
            } else if (chunk.status === "error") {
              toast.error(chunk.detail || "Server error during extraction.")
              console.error("[handleExtractData] chunk.status=error. detail:", chunk.detail)
            } else if (chunk.status === "completed" || chunk.data) {
              // This is your final chunk of extracted data
              console.log("[handleExtractData] Extraction completed. Setting final chunk to extractedData.")
              setExtractedData(chunk)
              setCurrentStep("check")
            }
          } catch (parseErr) {
            console.warn("[handleExtractData] JSON parse error for line:", trimmed, "Error:", parseErr)
          }
        }
      }
    } catch (err: any) {
      console.error("[handleExtractData] Outer catch error:", err)
      toast.error(err.message || "Extraction failed (outer catch).")
    } finally {
      setIsExtracting(false)
      console.log("[handleExtractData] Extraction process ended.")
    }
  }

  /**
   * Called after the data is successfully saved to /api/save-extracted-data.
   * Moves to the final confirmation step.
   */
  function handleDataSaved() {
    console.log("[handleDataSaved] Data was saved. Moving to 'confirmation' step.")
    setCurrentStep("confirmation")
  }

  function handleReset() {
    console.log("[handleReset] Resetting upload workflow.")
    setCurrentStep("upload")
    setSelectedFile(null)
    setExtractedData(null)
    setStreamStatus("")
    setSpeciesName("")
  }

  return (
    <div className="p-6 space-y-8">
      <StepIndicator currentStep={currentStep} />

      {/* Spinner / ongoing extraction */}
      {isExtracting && (
        <div className="flex items-center space-x-2 text-gray-600">
          <svg
            className="animate-spin h-5 w-5 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
          <span>Ongoing Extraction — {streamStatus}</span>
        </div>
      )}

      {currentStep === "upload" && (
        <UploadForm
          onFileSelect={setSelectedFile}
          onExtractData={handleExtractData}
          selectedFile={selectedFile}
          onSpeciesSelect={setSpeciesName}
        />
      )}

      {currentStep === "check" && extractedData && (
        <ReviewForm
          extractedData={extractedData}
          selectedFile={selectedFile}
          // Pass the user-selected species name to the review form
          speciesName={speciesName}
          // Called after data is successfully saved to DB
          onConfirm={handleDataSaved}
        />
      )}

      {currentStep === "check" && !extractedData && (
        <div className="bg-white p-4 text-red-600">
          <strong>[Warning]</strong> currentStep=check but extractedData is null/undefined
        </div>
      )}

      {currentStep === "confirmation" && (
        <ConfirmationMessage onReset={handleReset} />
      )}
    </div>
  )
}