// src/pages/About.jsx
import React from 'react';

const About = () => {
  return (
    <div className="mt-20 max-w-3xl mx-auto bg-white p-8 rounded-md shadow-md">
      {/* Section 1: About Atlaz */}
      <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Purpose</h2>
      <div className="text-gray-700 leading-relaxed space-y-4">
        <p>
          Knowledge is the key to a flourishing world. When we can extract general concepts from our 
          experiences and make them accessible to others, we reduce unnecessary suffering and turn the 
          remaining challenges into more meaningful endeavors. With the invention of language, it became 
          possible to make knowledge concrete and storable. On top of language sits all human progress. 
          Now, as LLMs (Large Language Models) revolutionize how we access and understand knowledge 
          from the internet, we stand on the brink of a new era of knowledge.
        </p>
        <p>
          LLMs excel at synthesizing information—writing code, answering questions, summarizing text, 
          and more. However, they struggle to truly understand the information they process. This is 
          where knowledge graphs come in. Knowledge graphs represent information in a structured, 
          machine-readable way, enabling both humans and AI to reason about information more effectively.
        </p>
        <p>
          GraphRAG has emerged as a powerful Knowledge Graph-based RAG (Retrieval Augmented Generation) strategy. But Knowledge Graphs 
          aren't just another tool in the RAG toolbox. It's a different modality which will be integral to 
          how LLMs will retrieve, memorize, and reason about information. With programmatic structures, 
          there's no need for AI to hallucinate answers—it can rely on a well-organized, verifiable 
          knowledge foundation.
        </p>
        <p>
          The key to unlocking safe AI adoption is to enable trust & organize data. Atlaz produces Knowledge Engines that allow you to build, visualize, and deploy 
          knowledge graphs, opening up a new wave of AI applications. Historically, building and maintaining 
          Knowledge Graphs required substantial technical expertise. We’re changing that. Atlaz can 
          automatically generate graphs from your domain-specific texts, and then serve them through 
          a simple Python library, ready to power your data extraction and integrate into your RAG pipelines. 
          For the really complex tasks, the end-to-end Atlaz Reasoning Engine is ready to tackle your toughest challenges with both retrieval and reasoning.
        </p>
      </div>

      {/* Section 2: Founder Information */}
      <div className="mt-12">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Journey</h2>
        <div className="flex flex-col items-center space-y-6">
          {/* Text Section */}
          <div className="text-gray-700 leading-relaxed">
            <p className="mb-4">
              Atlaz was founded by Rasmus Bjersing, based on a vision of a new era of AI powered by knowledge graphs.
            </p>
            <p className="mb-4">
              As a kid, I was fascinated by how to structure information. In primary school, I would spend hours drawing geographical maps, and as a student, I would spend days organizing my course material into meticulous mind maps in Microsoft Excel.
            </p>
            <p className="mb-4">
              After discovering Knowledge Graphs, I was hooked. It became a hobby taking up more and more of my time and grew into an integral way of how I worked and studied. The idea of a perfect data model and the potential it holds for AI was eventually too much to resist. I started Atlaz to empower others to build, visualize, and deploy knowledge graphs—turning raw data into powerful information structures for accurate, explainable, and safe AI.
            </p>
          </div>
          {/* Image Section */}
          <div className="flex justify-center">
            <img 
              src="/founder_image.png" 
              alt="Rasmus, Founder of Atlaz" 
              className="w-48 h-auto rounded-full shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;