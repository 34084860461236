// src/utils/highlightMatches.js

/**
 * Single-term highlighting:
 *   highlightMatches(text, query) 
 */
export function highlightMatches(text, query) {
    // Convert < and > to HTML entities
    let safe = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    
    // If no query, return safe text as-is (no \n -> <br/> here!)
    if (!query.trim()) {
      return safe;
    }
  
    // Escape special regex chars in query
    const escaped = query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    const regex = new RegExp(`(${escaped})`, 'gi');
  
    // Wrap matches in <mark>
    let replaced = safe.replace(regex, '<mark class="bg-green-300">$1</mark>');
    
    // Return replaced text (no .replace(/\n/g, '<br/>'))
    return replaced;
  }
  export function highlightMultipleMatches(html, queries) {
    // If no queries, just return the HTML
    if (!queries || !queries.length) return html;
  
    let result = html;
    queries.forEach(({ query, color, className }) => {
      if (!query.trim()) return;
  
      // Escape special regex chars in query
      const escaped = query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
      const regex = new RegExp(`(${escaped})`, 'gi');
  
      // Wrap matches in a <mark> with specified class and style
      result = result.replace(
        regex,
        `<mark class="${className}" style="background-color:${color}">$1</mark>`
      );
    });
  
    return result;
  }
  
  /**
   * Multi-term highlighting:
   *   highlightMultipleMatches(html, queries)
   *   queries = [ { query: "some text", color: "#ffe599" }, ... ]
   */
  export function highlightMultipleMatches2(html, queries) {
    // If no queries, just return the HTML
    if (!queries || !queries.length) return html;
  
    let result = html;
    queries.forEach(({ query, color }) => {
      if (!query.trim()) return;
  
      // Escape special regex chars in query
      const escaped = query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
      const regex = new RegExp(`(${escaped})`, 'gi');
  
      // Wrap matches in a <mark style="background-color: ...">
      result = result.replace(regex, `<mark style="background-color:${color}">$1</mark>`);
    });
  
    return result;
  }