// src/cached_examples.js

export const cachedExamples = {
    example1: {
        "nodes": [
            {
                "id": "100",
                "label": "Entity",
                "color": "lightgreen",
                "shape": "box",
                "info": "A general entity that serves as the root for all other entities."
            },
            {
                "id": "1",
                "label": "Tesla, Inc.",
                "color": "lightblue",
                "shape": "box",
                "info": "An American electric vehicle and clean energy company."
            },
            {
                "id": "2",
                "label": "Securities and Exchange Commission (SEC)",
                "color": "lightblue",
                "shape": "box",
                "info": "A U.S. government agency responsible for enforcing the federal securities laws and regulating the securities industry."
            },
            {
                "id": "3",
                "label": "Common Stock",
                "color": "lightyellow",
                "shape": "box",
                "info": "A type of security that represents ownership in a corporation."
            },
            {
                "id": "4",
                "label": "Convertible Senior Notes",
                "color": "lightyellow",
                "shape": "box",
                "info": "A type of debt security that can be converted into a predetermined number of the issuer's shares."
            },
            {
                "id": "30",
                "label": "Tesla Manufacturing Facility",
                "color": "orange",
                "shape": "box",
                "info": "A facility where Tesla manufactures its products."
            },
            {
                "id": "5",
                "label": "Fremont Factory",
                "color": "orange",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Fremont, California."
            },
            {
                "id": "6",
                "label": "Gigafactory Shanghai",
                "color": "orange",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Shanghai, China."
            },
            {
                "id": "7",
                "label": "Gigafactory Berlin-Brandenburg",
                "color": "orange",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Berlin-Brandenburg, Germany."
            },
            {
                "id": "8",
                "label": "Gigafactory Texas",
                "color": "orange",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Texas, USA."
            },
            {
                "id": "9",
                "label": "Gigafactory Nevada",
                "color": "orange",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Nevada, USA."
            },
            {
                "id": "10",
                "label": "Form 10-Q",
                "color": "pink",
                "shape": "box",
                "info": "A quarterly report filed by public companies to the SEC, providing a comprehensive overview of the company's financial performance."
            },
            {
                "id": "31",
                "label": "Legal Action",
                "color": "lime",
                "shape": "box",
                "info": "A process of taking legal action."
            },
            {
                "id": "11",
                "label": "Litigation",
                "color": "lime",
                "shape": "box",
                "info": "The process of taking legal action."
            },
            {
                "id": "12",
                "label": "Derivative Action",
                "color": "lime",
                "shape": "box",
                "info": "A lawsuit brought by a shareholder on behalf of a corporation against a third party."
            },
            {
                "id": "33",
                "label": "Tesla Product",
                "color": "lightgreen",
                "shape": "box",
                "info": "A product produced by Tesla."
            },
            {
                "id": "32",
                "label": "Tesla Vehicle",
                "color": "lightgreen",
                "shape": "box",
                "info": "A vehicle produced by Tesla."
            },
            {
                "id": "13",
                "label": "Model S",
                "color": "lightgreen",
                "shape": "box",
                "info": "A luxury all-electric sedan produced by Tesla."
            },
            {
                "id": "14",
                "label": "Model X",
                "color": "lightgreen",
                "shape": "box",
                "info": "A luxury all-electric SUV produced by Tesla."
            },
            {
                "id": "15",
                "label": "Model 3",
                "color": "lightgreen",
                "shape": "box",
                "info": "A compact all-electric sedan produced by Tesla."
            },
            {
                "id": "16",
                "label": "Model Y",
                "color": "lightgreen",
                "shape": "box",
                "info": "A compact all-electric SUV produced by Tesla."
            },
            {
                "id": "17",
                "label": "Cybertruck",
                "color": "lightgreen",
                "shape": "box",
                "info": "An all-electric pickup truck produced by Tesla."
            },
            {
                "id": "18",
                "label": "Tesla Semi",
                "color": "lightgreen",
                "shape": "box",
                "info": "An all-electric semi-truck produced by Tesla."
            },
            {
                "id": "19",
                "label": "Powerwall",
                "color": "lightgreen",
                "shape": "box",
                "info": "A rechargeable home battery system produced by Tesla."
            },
            {
                "id": "20",
                "label": "Megapack",
                "color": "lightgreen",
                "shape": "box",
                "info": "A large-scale battery storage product produced by Tesla."
            }
        ],
        "edges": [
            {
                "source": "100",
                "target": "1",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "30",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "31",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "33",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "2",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "3",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Common stock is a type of financial instrument."
            },
            {
                "source": "30",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Fremont Factory is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Shanghai is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Berlin-Brandenburg is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Texas is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Nevada is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "31",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Litigation is a type of Legal Action."
            },
            {
                "source": "31",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Derivative Action is a type of Legal Action."
            },
            {
                "source": "33",
                "target": "32",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Tesla Vehicle is a type of Tesla Product."
            },
            {
                "source": "33",
                "target": "19",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Powerwall is a type of Tesla Product."
            },
            {
                "source": "33",
                "target": "20",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Megapack is a type of Tesla Product."
            },
            {
                "source": "32",
                "target": "13",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model S is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "14",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model X is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "15",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model 3 is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "16",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model Y is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "17",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Cybertruck is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "18",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Tesla Semi is a type of Tesla Vehicle."
            },
            {
                "source": "1",
                "target": "10",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "files",
                "info": "Tesla, Inc. files Form 10-Q as part of its regulatory requirements."
            },
            {
                "source": "1",
                "target": "13",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Model S at the Fremont Factory."
            },
            {
                "source": "1",
                "target": "15",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Model 3 at Gigafactory Shanghai."
            },
            {
                "source": "1",
                "target": "16",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Model Y at Gigafactory Berlin-Brandenburg."
            },
            {
                "source": "1",
                "target": "17",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Cybertruck at Gigafactory Texas."
            },
            {
                "source": "1",
                "target": "18",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Tesla Semi at Gigafactory Nevada."
            },
            {
                "source": "1",
                "target": "11",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "involved in",
                "info": "Tesla, Inc. is involved in litigation related to its CEO Performance Award."
            },
            {
                "source": "1",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "involved in",
                "info": "Tesla, Inc. is involved in derivative actions filed by shareholders."
            },
            {
                "source": "2",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "regulates",
                "info": "The SEC regulates Tesla, Inc. as it is a public company."
            }
        ],
        "categories": [
            {
                "name": "Entities",
                "color": "lightgreen"
            },
            {
                "name": "Organizations",
                "color": "lightblue"
            },
            {
                "name": "Financial Instruments",
                "color": "lightyellow"
            },
            {
                "name": "Locations",
                "color": "orange"
            },
            {
                "name": "Financial Reports",
                "color": "pink"
            },
            {
                "name": "Legal Concepts",
                "color": "lime"
            },
            {
                "name": "Products",
                "color": "lightgreen"
            }
        ]
    },
    example2: {
        "nodes": [
            {
                "id": "1",
                "label": "Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A set with a law of composition that is associative, has a unit element, and every element has an inverse."
            },
            {
                "id": "2",
                "label": "Monoid",
                "color": "lightgreen",
                "shape": "box",
                "info": "A set with an associative law of composition and a unit element."
            },
            {
                "id": "3",
                "label": "Cyclic Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group generated by a single element."
            },
            {
                "id": "4",
                "label": "Abelian Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group where the law of composition is commutative."
            },
            {
                "id": "5",
                "label": "Normal Subgroup",
                "color": "lightgreen",
                "shape": "box",
                "info": "A subgroup that is invariant under conjugation by any element of the group."
            },
            {
                "id": "6",
                "label": "Factor Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group formed by the cosets of a normal subgroup."
            },
            {
                "id": "7",
                "label": "Permutation Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group whose elements are bijective transformations on a set."
            },
            {
                "id": "8",
                "label": "Symmetric Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "The group of all permutations of a finite set."
            },
            {
                "id": "9",
                "label": "Direct Product",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group formed by the Cartesian product of groups with componentwise operation."
            },
            {
                "id": "10",
                "label": "Mathematical Structure",
                "color": "lightgreen",
                "shape": "box",
                "info": "A general concept in mathematics that includes sets with operations."
            },
            {
                "id": "11",
                "label": "Mathematical Concept",
                "color": "lightblue",
                "shape": "box",
                "info": "A general concept in mathematics."
            },
            {
                "id": "12",
                "label": "Law of Composition",
                "color": "lightblue",
                "shape": "box",
                "info": "A rule that assigns to each pair of elements in a set a third element in the same set."
            },
            {
                "id": "13",
                "label": "Unit Element",
                "color": "lightblue",
                "shape": "box",
                "info": "An element that acts as an identity for the law of composition."
            },
            {
                "id": "14",
                "label": "Inverse Element",
                "color": "lightblue",
                "shape": "box",
                "info": "An element that, when combined with a given element under the law of composition, yields the unit element."
            },
            {
                "id": "15",
                "label": "Homomorphism",
                "color": "lightblue",
                "shape": "box",
                "info": "A structure-preserving map between two algebraic structures."
            },
            {
                "id": "16",
                "label": "Isomorphism",
                "color": "lightblue",
                "shape": "box",
                "info": "A bijective homomorphism."
            },
            {
                "id": "17",
                "label": "Kernel",
                "color": "lightblue",
                "shape": "box",
                "info": "The set of elements that map to the identity element under a homomorphism."
            },
            {
                "id": "18",
                "label": "Image",
                "color": "lightblue",
                "shape": "box",
                "info": "The set of all outputs of a function or homomorphism."
            },
            {
                "id": "19",
                "label": "Order of a Group",
                "color": "lightblue",
                "shape": "box",
                "info": "The number of elements in a group."
            },
            {
                "id": "20",
                "label": "Order of an Element",
                "color": "lightblue",
                "shape": "box",
                "info": "The smallest positive integer such that the element raised to that power is the identity element."
            },
            {
                "id": "21",
                "label": "Mathematician",
                "color": "lightyellow",
                "shape": "box",
                "info": "A person who specializes in the field of mathematics."
            },
            {
                "id": "22",
                "label": "N. H. Abel",
                "color": "lightyellow",
                "shape": "box",
                "info": "A mathematician known for his work in group theory, particularly abelian groups."
            },
            {
                "id": "23",
                "label": "E. Galois",
                "color": "lightyellow",
                "shape": "box",
                "info": "A mathematician known for his work in Galois theory and permutation groups."
            },
            {
                "id": "24",
                "label": "S. Lie",
                "color": "lightyellow",
                "shape": "box",
                "info": "A mathematician known for his work on transformation groups."
            },
            {
                "id": "25",
                "label": "H. Weber",
                "color": "lightyellow",
                "shape": "box",
                "info": "A mathematician known for his contributions to the axiomatic characterization of groups."
            },
            {
                "id": "26",
                "label": "Concept",
                "color": "orange",
                "shape": "box",
                "info": "A general idea or understanding of something."
            }
        ],
        "edges": [
            {
                "source": "2",
                "target": "1",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A group is a monoid with the additional property that every element has an inverse."
            },
            {
                "source": "1",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A cyclic group is a specific type of group generated by a single element."
            },
            {
                "source": "1",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "An abelian group is a group where the law of composition is commutative."
            },
            {
                "source": "1",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A normal subgroup is a subgroup that is invariant under conjugation by any element of the group."
            },
            {
                "source": "1",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A factor group is formed by the cosets of a normal subgroup."
            },
            {
                "source": "1",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A permutation group is a group whose elements are bijective transformations on a set."
            },
            {
                "source": "1",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A direct product is a group formed by the Cartesian product of groups with componentwise operation."
            },
            {
                "source": "7",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A symmetric group is a specific type of permutation group that includes all permutations of a finite set."
            },
            {
                "source": "11",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Law of Composition is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "13",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Unit Element is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "14",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Inverse Element is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "15",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Homomorphism is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "16",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Isomorphism is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "17",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Kernel is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "18",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Image is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "19",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Order of a Group is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "20",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Order of an Element is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Mathematical Concept is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "21",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Mathematical Concept is a fundamental mathematical concept."
            },
            {
                "source": "10",
                "target": "2",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Mathematical Structure is a fundamental mathematical concept."
            },
            {
                "source": "21",
                "target": "22",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "N. H. Abel is a mathematician."
            },
            {
                "source": "21",
                "target": "23",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "E. Galois is a mathematician."
            },
            {
                "source": "21",
                "target": "24",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "S. Lie is a mathematician."
            },
            {
                "source": "21",
                "target": "25",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "H. Weber is a mathematician."
            },
            {
                "source": "26",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Concept is a global concept that encompasses all other concepts."
            },
            {
                "source": "1",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The law of composition is a fundamental operation in the definition of a group."
            },
            {
                "source": "1",
                "target": "13",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The unit element acts as an identity in the law of composition for groups."
            },
            {
                "source": "1",
                "target": "14",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "An inverse element is required for a set to be considered a group."
            },
            {
                "source": "1",
                "target": "15",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "A homomorphism is a structure-preserving map between groups."
            },
            {
                "source": "1",
                "target": "16",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "An isomorphism is a bijective homomorphism between groups."
            },
            {
                "source": "1",
                "target": "19",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The order of a group is the number of elements in the group."
            },
            {
                "source": "1",
                "target": "20",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The order of an element is the smallest positive integer such that the element raised to that power is the identity element."
            },
            {
                "source": "15",
                "target": "17",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The kernel of a homomorphism is a normal subgroup of the domain group."
            },
            {
                "source": "15",
                "target": "18",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The image of a homomorphism is a subgroup of the codomain group."
            },
            {
                "source": "22",
                "target": "4",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "N. H. Abel is known for his work on abelian groups."
            },
            {
                "source": "23",
                "target": "7",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "E. Galois is known for his work on permutation groups and Galois theory."
            },
            {
                "source": "24",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "S. Lie contributed to the axiomatic characterization of groups."
            },
            {
                "source": "25",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "H. Weber contributed to the axiomatic characterization of groups."
            }
        ],
        "categories": [
            {
                "name": "Mathematical Structures",
                "color": "lightgreen"
            },
            {
                "name": "Mathematical Concepts",
                "color": "lightblue"
            },
            {
                "name": "Mathematicians",
                "color": "lightyellow"
            },
            {
                "name": "Global Concepts",
                "color": "orange"
            }
        ]
    },
    example3: {
        "nodes": [
            {
                "id": "2",
                "label": "Technological Concept",
                "color": "lightyellow",
                "shape": "box",
                "info": "A broad category encompassing various technologies and methodologies."
            },
            {
                "id": "3",
                "label": "Genome Editing Technology",
                "color": "orange",
                "shape": "box",
                "info": "A technology used to alter the genetic material of living organisms."
            },
            {
                "id": "4",
                "label": "CRISPR-Cas9",
                "color": "orange",
                "shape": "box",
                "info": "A genome editing technology that uses a CRISPR-associated protein 9 to edit genes."
            },
            {
                "id": "5",
                "label": "Zinc-Finger Nucleases (ZFNs)",
                "color": "orange",
                "shape": "box",
                "info": "A genome editing technology that uses engineered zinc-finger proteins to create double-strand breaks in DNA."
            },
            {
                "id": "6",
                "label": "Transcription Activator-Like Effector Nucleases (TALENs)",
                "color": "orange",
                "shape": "box",
                "info": "A genome editing technology that uses transcription activator-like effectors to create double-strand breaks in DNA."
            },
            {
                "id": "7",
                "label": "DNA Repair Mechanism",
                "color": "pink",
                "shape": "box",
                "info": "A biological process by which a cell identifies and corrects damage to the DNA molecules that encode its genome."
            },
            {
                "id": "8",
                "label": "Homologous Recombination (HR)",
                "color": "pink",
                "shape": "box",
                "info": "A molecular mechanism for DNA repair that uses a homologous sequence as a template for repair."
            },
            {
                "id": "9",
                "label": "Non-Homologous End Joining (NHEJ)",
                "color": "pink",
                "shape": "box",
                "info": "A molecular mechanism for DNA repair that directly ligates the ends of DNA without a homologous template."
            },
            {
                "id": "10",
                "label": "Application of Genome Editing",
                "color": "lime",
                "shape": "box",
                "info": "General applications of genome editing technologies."
            },
            {
                "id": "11",
                "label": "Gene Therapy",
                "color": "lime",
                "shape": "box",
                "info": "The use of genome editing technologies to treat or prevent diseases by correcting defective genes."
            },
            {
                "id": "12",
                "label": "Haematological Disorders",
                "color": "lime",
                "shape": "box",
                "info": "Disorders related to blood and its components, which can be treated using genome editing technologies."
            },
            {
                "id": "13",
                "label": "Research and Development Application",
                "color": "lightgreen",
                "shape": "box",
                "info": "Applications of technologies in research and development."
            },
            {
                "id": "14",
                "label": "CRISPR Interference (CRISPRi)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A technology that uses a catalytically inactive Cas9 to block transcription of target genes."
            },
            {
                "id": "15",
                "label": "CRISPR Activation (CRISPRa)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A technology that uses a modified Cas9 to activate transcription of target genes."
            }
        ],
        "edges": [
            {
                "source": "2",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Genome Editing Technology is a type of technological concept."
            },
            {
                "source": "2",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "DNA Repair Mechanism is a type of technological concept."
            },
            {
                "source": "2",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Application of Genome Editing is a type of technological concept."
            },
            {
                "source": "2",
                "target": "13",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Research and Development Application is a type of technological concept."
            },
            {
                "source": "3",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "CRISPR-Cas9 is a type of genome editing technology."
            },
            {
                "source": "3",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Zinc-Finger Nucleases are a type of genome editing technology."
            },
            {
                "source": "3",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Transcription Activator-Like Effector Nucleases are a type of genome editing technology."
            },
            {
                "source": "7",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Homologous Recombination is a type of DNA repair mechanism."
            },
            {
                "source": "7",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Non-Homologous End Joining is a type of DNA repair mechanism."
            },
            {
                "source": "10",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gene Therapy is an application of genome editing."
            },
            {
                "source": "10",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Haematological Disorders are a type of application of genome editing."
            },
            {
                "source": "13",
                "target": "14",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "CRISPR Interference is a research and development application of CRISPR-Cas9."
            },
            {
                "source": "13",
                "target": "15",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "CRISPR Activation is a research and development application of CRISPR-Cas9."
            },
            {
                "source": "4",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "used for",
                "info": "CRISPR-Cas9 is used to correct mutations in β-thalassaemia, sickle-cell disease, Fanconi anaemia, Diamond-Blackfan anaemia, thrombocytopenia, and haemophilia."
            },
            {
                "source": "4",
                "target": "8",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "utilizes",
                "info": "CRISPR-Cas9 can utilize HDR for precise genome editing."
            },
            {
                "source": "4",
                "target": "9",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "utilizes",
                "info": "CRISPR-Cas9 can utilize NHEJ for genome editing."
            }
        ],
        "categories": [
            {
                "name": "Global Root",
                "color": "lightgreen"
            },
            {
                "name": "Conceptual Framework",
                "color": "lightblue"
            },
            {
                "name": "Technological Concepts",
                "color": "lightyellow"
            },
            {
                "name": "Genome Editing Technologies",
                "color": "orange"
            },
            {
                "name": "Molecular Mechanisms for DNA Repair",
                "color": "pink"
            },
            {
                "name": "Applications of Genome Editing",
                "color": "lime"
            },
            {
                "name": "Research and Development",
                "color": "lightgreen"
            },
            {
                "name": "Relationship Types",
                "color": "lightblue"
            }
        ]
    },
    example4: {
        "nodes": [
            {
                "id": "1",
                "label": "Transformer",
                "color": "lightgreen",
                "shape": "box",
                "info": "A model architecture based solely on attention mechanisms, dispensing with recurrence and convolutions entirely."
            },
            {
                "id": "2",
                "label": "Recurrent Neural Network (RNN)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A type of neural network where connections between nodes form a directed graph along a temporal sequence."
            },
            {
                "id": "3",
                "label": "Convolutional Neural Network (CNN)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A class of deep neural networks, most commonly applied to analyzing visual imagery."
            },
            {
                "id": "4",
                "label": "Encoder-Decoder Architecture",
                "color": "lightgreen",
                "shape": "box",
                "info": "A neural network design pattern for sequence-to-sequence tasks, consisting of an encoder to process the input and a decoder to generate the output."
            },
            {
                "id": "20",
                "label": "Neural Network Model",
                "color": "lightgreen",
                "shape": "box",
                "info": "A computational model inspired by the way biological neural networks in the human brain process information."
            },
            {
                "id": "5",
                "label": "Self-Attention",
                "color": "lightblue",
                "shape": "box",
                "info": "An attention mechanism relating different positions of a single sequence to compute a representation of the sequence."
            },
            {
                "id": "6",
                "label": "Scaled Dot-Product Attention",
                "color": "lightblue",
                "shape": "box",
                "info": "An attention mechanism where the dot products of the query with all keys are computed, divided by the square root of the dimension of the keys, and a softmax function is applied to obtain the weights on the values."
            },
            {
                "id": "7",
                "label": "Multi-Head Attention",
                "color": "lightblue",
                "shape": "box",
                "info": "An attention mechanism that allows the model to jointly attend to information from different representation subspaces at different positions."
            },
            {
                "id": "21",
                "label": "Attention Mechanism",
                "color": "lightblue",
                "shape": "box",
                "info": "A process that allows a model to focus on specific parts of the input sequence when producing an output."
            },
            {
                "id": "8",
                "label": "Machine Translation",
                "color": "lightyellow",
                "shape": "box",
                "info": "The task of automatically converting text from one language to another."
            },
            {
                "id": "9",
                "label": "English Constituency Parsing",
                "color": "lightyellow",
                "shape": "box",
                "info": "The task of analyzing the syntactic structure of a sentence according to a constituency-based grammar."
            },
            {
                "id": "22",
                "label": "Task",
                "color": "lightyellow",
                "shape": "box",
                "info": "A specific function or activity that a model is designed to perform."
            },
            {
                "id": "10",
                "label": "BLEU Score",
                "color": "orange",
                "shape": "box",
                "info": "A metric for evaluating a generated sentence to a reference sentence, used in machine translation."
            },
            {
                "id": "11",
                "label": "Dropout",
                "color": "pink",
                "shape": "box",
                "info": "A regularization technique for reducing overfitting in neural networks by preventing complex co-adaptations on training data."
            },
            {
                "id": "12",
                "label": "Label Smoothing",
                "color": "pink",
                "shape": "box",
                "info": "A technique used during training to make the model less confident, improving accuracy and BLEU score."
            },
            {
                "id": "23",
                "label": "Training Technique",
                "color": "pink",
                "shape": "box",
                "info": "A method or strategy used to improve the performance of a model during training."
            },
            {
                "id": "24",
                "label": "Concept",
                "color": "lime",
                "shape": "box",
                "info": "A general idea or understanding of something."
            }
        ],
        "edges": [
            {
                "source": "20",
                "target": "1",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "The Transformer is a type of neural network model."
            },
            {
                "source": "20",
                "target": "2",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Recurrent Neural Networks are a type of neural network model."
            },
            {
                "source": "20",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Convolutional Neural Networks are a type of neural network model."
            },
            {
                "source": "20",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Encoder-Decoder Architecture is a type of neural network model."
            },
            {
                "source": "21",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Self-Attention is a type of attention mechanism."
            },
            {
                "source": "21",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Scaled Dot-Product Attention is a type of attention mechanism."
            },
            {
                "source": "21",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Multi-Head Attention is a type of attention mechanism."
            },
            {
                "source": "22",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Machine Translation is a type of task."
            },
            {
                "source": "22",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "English Constituency Parsing is a type of task."
            },
            {
                "source": "23",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Dropout is a type of training technique."
            },
            {
                "source": "23",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Label Smoothing is a type of training technique."
            },
            {
                "source": "24",
                "target": "21",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Attention Mechanism is a type of concept."
            },
            {
                "source": "24",
                "target": "20",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Neural Network Model is a type of concept."
            },
            {
                "source": "24",
                "target": "22",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Task is a type of concept."
            },
            {
                "source": "24",
                "target": "23",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Training Technique is a type of concept."
            },
            {
                "source": "24",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Metric is a type of concept."
            },
            {
                "source": "1",
                "target": "5",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "uses",
                "info": "The Transformer uses self-attention to compute representations of its input and output."
            },
            {
                "source": "1",
                "target": "10",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "achieves",
                "info": "The Transformer achieves a BLEU score of 28.4 on the WMT 2014 English-to-German translation task."
            },
            {
                "source": "1",
                "target": "10",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "achieves",
                "info": "The Transformer achieves a BLEU score of 41.8 on the WMT 2014 English-to-French translation task."
            },
            {
                "source": "1",
                "target": "8",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "applied_to",
                "info": "Machine Translation is a task where the Transformer has been applied successfully."
            },
            {
                "source": "1",
                "target": "9",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "applied_to",
                "info": "English Constituency Parsing is a task where the Transformer has been applied successfully."
            },
            {
                "source": "11",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "used_in",
                "info": "Dropout is used in the training of the Transformer to prevent overfitting."
            },
            {
                "source": "12",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "used_in",
                "info": "Label Smoothing is used in the training of the Transformer to improve accuracy and BLEU score."
            }
        ],
        "categories": [
            {
                "name": "Neural Network Models",
                "color": "lightgreen"
            },
            {
                "name": "Attention Mechanisms",
                "color": "lightblue"
            },
            {
                "name": "Tasks",
                "color": "lightyellow"
            },
            {
                "name": "Metrics",
                "color": "orange"
            },
            {
                "name": "Training Techniques",
                "color": "pink"
            },
            {
                "name": "Concepts",
                "color": "lime"
            }
        ]
    },
    example5: {
        "nodes": [
            {
                "id": "1",
                "label": "Entity",
                "color": "lightgreen",
                "shape": "ellipse",
                "info": ""
            },
            {
                "id": "7",
                "label": "Parking Spot",
                "color": "lightyellow",
                "shape": "polygon",
                "info": "Check if you are in an allowed parking spot. If you’re not in an allowed parking spot, use the app to locate the closest allowed parking area and bring the scooter there to end your ride."
            },
            {
                "id": "8",
                "label": "No-Parking Zone",
                "color": "lightyellow",
                "shape": "polygon",
                "info": "it's not a no-parking zone or no-riding zone."
            },
            {
                "id": "9",
                "label": "No-Riding Zone",
                "color": "lightyellow",
                "shape": "polygon",
                "info": "it's not a no-parking zone or no-riding zone."
            },
            {
                "id": "10",
                "label": "Voi User",
                "color": "orange",
                "shape": "box",
                "info": "If you’re a Voi user, the easiest way to contact us is by starting a chat directly in our app!"
            },
            {
                "id": "11",
                "label": "Voi App",
                "color": "pink",
                "shape": "egg",
                "info": "If you’re a Voi user, the easiest way to contact us is by starting a chat directly in our app!"
            },
            {
                "id": "12",
                "label": "Chat Support",
                "color": "lime",
                "shape": "diamond",
                "info": "chat directly in our app! You can access the chat by selecting ‘Help’ and then ‘Chat with us’.\n\nIf you don’t have the Voi app and would like to contact us, chat is also available on our website."
            },
            {
                "id": "13",
                "label": "Allowed Parking Spot",
                "color": "lightyellow",
                "shape": "polygon",
                "info": "park the scooter at an allowed parking spot and we will end the ride for you."
            },
            {
                "id": "14",
                "label": "Idle Scooter",
                "color": "lightblue",
                "shape": "circle",
                "info": "The ride will automatically end if the scooter remains idle for 10 minutes to ensure a smooth experience for you."
            },
            {
                "id": "15",
                "label": "Improper Parking Fine",
                "color": "lightyellow",
                "shape": "polygon",
                "info": ""
            },
            {
                "id": "16",
                "label": "Scooter",
                "color": "lightblue",
                "shape": "circle",
                "info": "bring the scooter there to end your ride. \n\nIn some cities, you may have designated parking spots that you must park at whereas in other cities, you can park anywhere so long as it's not a no-parking zone or no-riding zone. You can check our website to find the specific parking rules for your city.\n\nIf you're in a city where you must park at a designated parking spot, these spots will appear as a blue 'P' in the app:\nIf you're in a city where you can park anywhere except for in no-parking zones or no-riding zones, make sure to avoid the red or grey areas in the app:\nMake sure you’re standing right next to the scooter.\n\nRestart the app and try ending the ride again.\n\nMake sure you have the latest version of the app installed.\n\nIf you’re still unable to end your ride after checking each of the above steps, please contact us via our chat directly in the app for the quickest help in ending your ride.\n```\n\nHow do I get in contact with support?\n\n```Answer\nIf you’re a Voi user, the easiest way to contact us is by starting a chat directly in our app! You can access the chat by selecting ‘Help’ and then ‘Chat with us’.\n\nIf you don’t have the Voi app and would like to contact us, chat is also available on our website. \n```\n\nMy phone ran out of battery during my ride. Now what?\n\n```Answer\nDon’t worry, we’ve got you covered! If your phone battery dies, just make sure to park the scooter at an allowed parking spot and we will end the ride for you. The ride will automatically end if the scooter remains idle for 10 minutes to ensure a smooth experience for you."
            }
        ],
        "edges": [
            {
                "source": "16",
                "target": "7",
                "type": "other",
                "color": "black",
                "arrowhead": "normal",
                "label": "Can Park In",
                "info": "Check if you are in an allowed parking spot. If you’re not in an allowed parking spot, use the app to locate the closest allowed parking area and bring the scooter there to end your ride."
            },
            {
                "source": "16",
                "target": "8",
                "type": "other",
                "color": "black",
                "arrowhead": "normal",
                "label": "Cannot Park In",
                "info": "it's not a no-parking zone or no-riding zone."
            },
            {
                "source": "16",
                "target": "9",
                "type": "other",
                "color": "black",
                "arrowhead": "normal",
                "label": "Cannot Ride In",
                "info": "it's not a no-parking zone or no-riding zone."
            },
            {
                "source": "10",
                "target": "11",
                "type": "other",
                "color": "black",
                "arrowhead": "normal",
                "label": "Uses",
                "info": "If you’re a Voi user, the easiest way to contact us is by starting a chat directly in our app!"
            },
            {
                "source": "10",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "normal",
                "label": "Contacts Via",
                "info": "chat directly in our app! You can access the chat by selecting ‘Help’ and then ‘Chat with us’.\n\nIf you don’t have the Voi app and would like to contact us, chat is also available on our website."
            },
            {
                "source": "1",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Check if you are in an allowed parking spot. If you’re not in an allowed parking spot, use the app to locate the closest allowed parking area and bring the scooter there to end your ride."
            },
            {
                "source": "1",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "If you’re a Voi user, the easiest way to contact us is by starting a chat directly in our app!"
            },
            {
                "source": "1",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "If you’re a Voi user, the easiest way to contact us is by starting a chat directly in our app!"
            },
            {
                "source": "1",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "chat directly in our app! You can access the chat by selecting ‘Help’ and then ‘Chat with us’.\n\nIf you don’t have the Voi app and would like to contact us, chat is also available on our website."
            },
            {
                "source": "1",
                "target": "15",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": ""
            },
            {
                "source": "7",
                "target": "13",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "park the scooter at an allowed parking spot and we will end the ride for you."
            },
            {
                "source": "7",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "it's not a no-parking zone or no-riding zone."
            },
            {
                "source": "7",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "it's not a no-parking zone or no-riding zone."
            },
            {
                "source": "16",
                "target": "14",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "bring the scooter"
            },
            {
                "source": "1",
                "target": "16",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "if the scooter remains idle"
            }
        ],
        "categories": [
            {
                "name": "Entity",
                "color": "lightblue"
            },
            {
                "name": "Taxonomy 5 – Taxonomy of Entities",
                "color": "lightgreen"
            },
            {
                "name": "Taxonomy 6 – Taxonomy of Relationships",
                "color": "lightblue"
            },
            {
                "name": "Taxonomy 11 – Taxonomy of Parking Zones",
                "color": "lightyellow"
            },
            {
                "name": "Taxonomy 12 – Taxonomy of Users",
                "color": "orange"
            },
            {
                "name": "Taxonomy 13 – Taxonomy of Applications",
                "color": "pink"
            },
            {
                "name": "Taxonomy 14 – Taxonomy of Support Services",
                "color": "lime"
            },
            {
                "name": "Taxonomy 15 – Taxonomy of Scooter Relationships",
                "color": "lightgreen"
            },
            {
                "name": "Taxonomy 16 – Taxonomy of Scooters",
                "color": "lightblue"
            }
        ]
    },
    example6: {
    "categories": [{"name": 'Account', "color": 'lightgreen'},
        {"name": 'Order', "color": 'lightblue'},
        {"name": 'Payment', "color": 'lightyellow'},
        {"name": 'Delivery', "color": 'orange'},
        {"name": 'Registration', "color": 'pink'},
        {"name": 'Charging', "color": 'lime'},
        {"name": 'Insurance', "color": 'lightgreen'},
        {"name": 'Tracking', "color": 'lightblue'},
        {"name": 'Document', "color": 'lightyellow'},
        {"name": 'Location', "color": 'orange'},
        {"name": 'Support', "color": 'pink'},
        {"name": 'Financing', "color": 'lime'},
        {"name": 'Global', "color": 'lightgreen'}],
        "edges": [
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including accounts.",
            "label": "type of",
            "source": "35",
            "target": "1",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including payments.",
            "label": "type of",
            "source": "35",
            "target": "32",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including delivery.",
            "label": "type of",
            "source": "35",
            "target": "7",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including registration.",
            "label": "type of",
            "source": "35",
            "target": "9",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including charging.",
            "label": "type of",
            "source": "35",
            "target": "33",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including insurance.",
            "label": "type of",
            "source": "35",
            "target": "12",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including tracking.",
            "label": "type of",
            "source": "35",
            "target": "13",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including documents.",
            "label": "type of",
            "source": "35",
            "target": "34",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including locations.",
            "label": "type of",
            "source": "35",
            "target": "16",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including support.",
            "label": "type of",
            "source": "35",
            "target": "17",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla System encompasses all aspects of Tesla vehicle management, including financing.",
            "label": "type of",
            "source": "35",
            "target": "18",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "A Tesla Account is used to manage orders, including placement and cancellation.",
            "label": "type of",
            "source": "1",
            "target": "31",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "Order Placement is part of the Order Process.",
            "label": "type of",
            "source": "31",
            "target": "2",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "Order Cancellation is part of the Order Process.",
            "label": "type of",
            "source": "31",
            "target": "3",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "Payment methods are part of the payment process for a Tesla vehicle.",
            "label": "type of",
            "source": "32",
            "target": "4",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The final invoice is a document related to the payment process.",
            "label": "type of",
            "source": "32",
            "target": "5",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Destination and Doc Fee is part of the payment process.",
            "label": "type of",
            "source": "32",
            "target": "6",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "A delivery appointment is part of the delivery process.",
            "label": "type of",
            "source": "7",
            "target": "8",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "Home Charging is a type of Charging Option.",
            "label": "type of",
            "source": "33",
            "target": "10",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "Public Charging is a type of Charging Option.",
            "label": "type of",
            "source": "33",
            "target": "11",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "Registration documents are part of the vehicle registration process.",
            "label": "type of",
            "source": "34",
            "target": "15",
            "type": "subtype"
            },
            {
            "arrowhead": "normal",
            "color": "black",
            "info": "Final Invoice is a type of Vehicle Document.",
            "label": "type of",
            "source": "34",
            "target": "14",
            "type": "subtype"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage delivery appointments and dates.",
            "label": "manages",
            "source": "1",
            "target": "7",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage delivery appointments and dates.",
            "label": "manages",
            "source": "1",
            "target": "8",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage payment methods and final payments.",
            "label": "manages",
            "source": "1",
            "target": "4",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage payment methods and final payments.",
            "label": "manages",
            "source": "1",
            "target": "5",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage vehicle registration.",
            "label": "manages",
            "source": "1",
            "target": "9",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage documents, including the final invoice and registration documents.",
            "label": "manages",
            "source": "1",
            "target": "14",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage documents, including the final invoice and registration documents.",
            "label": "manages",
            "source": "1",
            "target": "15",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Tesla Service Centers provide locations for purchasing vehicle accessories.",
            "label": "provides",
            "source": "16",
            "target": "10",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Tesla Service Centers provide locations for purchasing vehicle accessories.",
            "label": "provides",
            "source": "16",
            "target": "11",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Tesla Preferred Partners offer financing options for purchasing a Tesla vehicle.",
            "label": "offers",
            "source": "18",
            "target": "4",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Tesla Preferred Partners offer financing options for purchasing a Tesla vehicle.",
            "label": "offers",
            "source": "18",
            "target": "5",
            "type": "other"
            },
            {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Tesla Account Support provides resources for managing a Tesla Account.",
            "label": "provides",
            "source": "17",
            "target": "1",
            "type": "other"
            }
        ],
        "nodes": [
            {"id": '1', "label": 'Tesla Account', "color": 'lightgreen', "shape": 'box', "info": 'An online account for Tesla customers to manage their orders, delivery, and other resources.', 'references': ['After ordering your vehicle, set up a Tesla Account with the email address used to place your order. Complete your pre-delivery tasks in your Tesla Account to receive a delivery appointment.']},
            {"id": '31', "label": 'Order Process', "color": 'lightblue', "shape": 'box', "info": 'The overall process of managing Tesla vehicle orders, including placement and cancellation.'},
            {"id": '2', "label": 'Order Placement', "color": 'lightblue', "shape": 'box', "info": 'The process of designing and submitting an order for a Tesla vehicle.'},
            {"id": '3', "label": 'Order Cancellation', "color": 'lightblue', "shape": 'box', "info": 'The process of canceling a reservation or order for a Tesla vehicle.'},
            {"id": '32', "label": 'Payment Process', "color": 'lightyellow', "shape": 'box', "info": 'The overall process of managing payments for a Tesla vehicle, including methods and fees.'},
            {"id": '4', "label": 'Payment Method', "color": 'lightyellow', "shape": 'box', "info": 'The method used to pay for a Tesla vehicle, includ…r, manager’s cheque, cash deposit, and financing.'},
            {"id": '5', "label": 'Final Payment', "color": 'lightyellow', "shape": 'box', "info": 'The last payment made before taking delivery of a Tesla vehicle.'},
            {"id": '6', "label": 'Destination and Doc Fee', "color": 'lightyellow', "shape": 'box', "info": 'A fee that includes administration, registration, and documentation fees for a Tesla vehicle.'},
            {"id": '7', "label": 'Delivery Appointment', "color": 'orange', "shape": 'box', "info": 'A scheduled appointment for the delivery of a Tesla vehicle.'},
            {"id": '8', "label": 'Delivery Date', "color": 'orange', "shape": 'box', "info": 'The date on which a Tesla vehicle is delivered to the customer.'},
            {"id": '9', "label": 'Vehicle Registration', "color": 'pink', "shape": 'box', "info": 'The process of registering a Tesla vehicle with the provided information.'},
            {"id": '33', "label": 'Charging Options', "color": 'lime', "shape": 'box', "info": 'The various options available for charging a Tesla vehicle.'},
            {"id": '10', "label": 'Home Charging', "color": 'lime', "shape": 'box', "info": 'Charging a Tesla vehicle at home using a Type 2 charging cable.'},
            {"id": '11', "label": 'Public Charging', "color": 'lime', "shape": 'box', "info": 'Charging a Tesla vehicle at public charging stations, including the Supercharger network.'},
            {"id": '12', "label": 'Motor Vehicle Insurance', "color": 'lightgreen', "shape": 'box', "info": 'Insurance required before taking delivery of a Tesla vehicle.'},
            {"id": '13', "label": 'Tesla Tracker', "color": 'lightblue', "shape": 'box', "info": 'A GPS tracking system to locate a Tesla vehicle through the Tesla app.'},
            {"id": '34', "label": 'Vehicle Documents', "color": 'lightyellow', "shape": 'box', "info": 'Documents related to the purchase and registration of a Tesla vehicle.'},
            {"id": '14', "label": 'Final Invoice', "color": 'lightyellow', "shape": 'box', "info": 'The final invoice for a Tesla vehicle order, available in the Tesla Account.'},
            {"id": '15', "label": 'Registration Documents', "color": 'lightyellow', "shape": 'box', "info": 'Documents required for the registration of a Tesla vehicle.'},
            {"id": '16', "label": 'Tesla Service Center', "color": 'orange', "shape": 'box', "info": 'A location where Tesla vehicle accessories can be purchased and services are provided.'},
            {"id": '17', "label": 'Tesla Account Support', "color": 'pink', "shape": 'box', "info": 'Support resources for managing a Tesla Account and related tasks.'},
            {"id": '18', "label": 'Tesla Preferred Partner', "color": 'lime', "shape": 'box', "info": 'A partner that provides financing options for purchasing a Tesla vehicle.'},
            {"id": '35', "label": 'Tesla System', "color": 'lightgreen', "shape": 'box', "info": 'The overarching system encompassing all aspects of…management, from ordering to delivery and beyond.'}]
        },
    example7: {
        "categories": [
          {
            "name": "Account Management",
            "color": "lightgreen"
          },
          {
            "name": "Payment and Financing",
            "color": "lightblue"
          },
          {
            "name": "Registration",
            "color": "lightyellow"
          },
          {
            "name": "Charging Solutions",
            "color": "orange"
          },
          {
            "name": "Insurance and Security",
            "color": "pink"
          },
          {
            "name": "Cancellations",
            "color": "lime"
          },
          {
            "name": "Tesla Operations",
            "color": "lightgreen"
          }
        ],
        "edges": [
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "A Tesla Account is used to manage orders, which includes order placement.",
            "label": "type of",
            "source": "1",
            "target": "2",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Pre-delivery tasks are part of the Tesla Account management.",
            "label": "type of",
            "source": "1",
            "target": "3",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Document access is a feature of the Tesla Account.",
            "label": "type of",
            "source": "1",
            "target": "4",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "VIN assignment is part of the Tesla Account management process.",
            "label": "type of",
            "source": "1",
            "target": "5",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Payment methods are part of the payment and financing process.",
            "label": "type of",
            "source": "6",
            "target": "7",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "The final invoice is part of the payment and financing process.",
            "label": "type of",
            "source": "6",
            "target": "8",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "A down payment is part of the payment and financing process.",
            "label": "type of",
            "source": "6",
            "target": "9",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Tesla Preferred Partner is part of the payment and financing process.",
            "label": "type of",
            "source": "6",
            "target": "10",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Destination and Doc Fee is part of the payment methods.",
            "label": "type of",
            "source": "7",
            "target": "11",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Destination and Doc Fee is part of the final invoice.",
            "label": "type of",
            "source": "8",
            "target": "11",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Vehicle registration is part of the registration process.",
            "label": "type of",
            "source": "12",
            "target": "13",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "The license plate number is part of the vehicle registration process.",
            "label": "type of",
            "source": "12",
            "target": "14",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Charging solutions include the Supercharger Network.",
            "label": "type of",
            "source": "15",
            "target": "16",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Home charging is part of the charging solutions offered by Tesla.",
            "label": "type of",
            "source": "15",
            "target": "17",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "A Wall Connector is part of the Supercharger Network.",
            "label": "type of",
            "source": "16",
            "target": "18",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "A Wall Connector is part of the home charging solution.",
            "label": "type of",
            "source": "17",
            "target": "18",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Motor vehicle insurance is part of the insurance and security requirements.",
            "label": "type of",
            "source": "19",
            "target": "20",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "The Tesla Tracker is part of the insurance and security features.",
            "label": "type of",
            "source": "19",
            "target": "21",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Order cancellation is part of the cancellation process.",
            "label": "type of",
            "source": "22",
            "target": "23",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Cancellation processing time is part of the cancellation process.",
            "label": "type of",
            "source": "22",
            "target": "24",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Account Management is part of Tesla Operations.",
            "label": "type of",
            "source": "25",
            "target": "1",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Payment and Financing is part of Tesla Operations.",
            "label": "type of",
            "source": "25",
            "target": "6",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Registration is part of Tesla Operations.",
            "label": "type of",
            "source": "25",
            "target": "12",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Charging Solutions is part of Tesla Operations.",
            "label": "type of",
            "source": "25",
            "target": "15",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Insurance and Security is part of Tesla Operations.",
            "label": "type of",
            "source": "25",
            "target": "19",
            "type": "subtype"
          },
          {
            "arrowhead": "normal",
            "color": "black",
            "info": "Cancellations is part of Tesla Operations.",
            "label": "type of",
            "source": "25",
            "target": "22",
            "type": "subtype"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account is used to manage pre-delivery tasks.",
            "label": "manages",
            "source": "1",
            "target": "3",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account provides access to documents.",
            "label": "provides access to",
            "source": "1",
            "target": "4",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Account shows the VIN once assigned.",
            "label": "displays",
            "source": "1",
            "target": "5",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The final invoice is available in the Tesla Account.",
            "label": "available in",
            "source": "8",
            "target": "1",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Payment methods are confirmed in the Tesla Account.",
            "label": "confirmed in",
            "source": "7",
            "target": "1",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The down payment is required for vehicle registration.",
            "label": "required for",
            "source": "9",
            "target": "12",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Supercharger Network is part of Tesla's charging solutions.",
            "label": "part of",
            "source": "16",
            "target": "15",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Motor vehicle insurance is required before delivery.",
            "label": "required before",
            "source": "20",
            "target": "1",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "The Tesla Tracker is used to locate the vehicle.",
            "label": "locates",
            "source": "21",
            "target": "1",
            "type": "other"
          },
          {
            "arrowhead": "diamond",
            "color": "black",
            "info": "Order cancellation is processed by contacting an advisor.",
            "label": "processed by",
            "source": "23",
            "target": "1",
            "type": "other"
          }
        ],
        "nodes": [
          {
            "color": "lightgreen",
            "id": "1",
            "info": "An online account for managing Tesla orders and deliveries.",
            "label": "Tesla Account",
            "shape": "box"
          },
          {
            "color": "lightgreen",
            "id": "2",
            "info": "The process of designing and submitting an order for a Tesla vehicle.",
            "label": "Order Placement",
            "shape": "box"
          },
          {
            "color": "lightgreen",
            "id": "3",
            "info": "Tasks to be completed in the Tesla Account before delivery.",
            "label": "Pre-Delivery Tasks",
            "shape": "box"
          },
          {
            "color": "lightgreen",
            "id": "4",
            "info": "Accessing documents in the Tesla Account.",
            "label": "Document Access",
            "shape": "box"
          },
          {
            "color": "lightgreen",
            "id": "5",
            "info": "The process of assigning a Vehicle Identification Number to a Tesla order.",
            "label": "VIN Assignment",
            "shape": "box"
          },
          {
            "color": "lightblue",
            "id": "6",
            "info": "The overall process of handling payments and financing for Tesla vehicles.",
            "label": "Payment and Financing Process",
            "shape": "box"
          },
          {
            "color": "lightblue",
            "id": "7",
            "info": "Methods accepted by Tesla for vehicle payment.",
            "label": "Payment Methods",
            "shape": "box"
          },
          {
            "color": "lightblue",
            "id": "8",
            "info": "The final billing document for a Tesla order.",
            "label": "Final Invoice",
            "shape": "box"
          },
          {
            "color": "lightblue",
            "id": "9",
            "info": "An initial payment required for financing a Tesla vehicle.",
            "label": "Down Payment",
            "shape": "box"
          },
          {
            "color": "lightblue",
            "id": "10",
            "info": "Financial institutions partnered with Tesla for vehicle financing.",
            "label": "Tesla Preferred Partner",
            "shape": "box"
          },
          {
            "color": "lightblue",
            "id": "11",
            "info": "Fees for vehicle administration, registration, and documentation.",
            "label": "Destination and Doc Fee",
            "shape": "box"
          },
          {
            "color": "lightyellow",
            "id": "12",
            "info": "The process of registering a Tesla vehicle.",
            "label": "Vehicle Registration",
            "shape": "box"
          },
          {
            "color": "lightyellow",
            "id": "13",
            "info": "The name under which a Tesla vehicle is registered.",
            "label": "Registration Name",
            "shape": "box"
          },
          {
            "color": "lightyellow",
            "id": "14",
            "info": "The official number plate assigned to a Tesla vehicle.",
            "label": "License Plate Number",
            "shape": "box"
          },
          {
            "color": "orange",
            "id": "15",
            "info": "Solutions provided by Tesla for charging vehicles.",
            "label": "Charging Solutions",
            "shape": "box"
          },
          {
            "color": "orange",
            "id": "16",
            "info": "Tesla's network of fast-charging stations.",
            "label": "Supercharger Network",
            "shape": "box"
          },
          {
            "color": "orange",
            "id": "17",
            "info": "Charging a Tesla vehicle at home.",
            "label": "Home Charging",
            "shape": "box"
          },
          {
            "color": "orange",
            "id": "18",
            "info": "A device for home charging of Tesla vehicles.",
            "label": "Wall Connector",
            "shape": "box"
          },
          {
            "color": "pink",
            "id": "19",
            "info": "Requirements and features for Tesla vehicle insurance and security.",
            "label": "Insurance and Security",
            "shape": "box"
          },
          {
            "color": "pink",
            "id": "20",
            "info": "Insurance required for Tesla vehicle delivery.",
            "label": "Motor Vehicle Insurance",
            "shape": "box"
          },
          {
            "color": "pink",
            "id": "21",
            "info": "A GPS tracking system for locating a Tesla vehicle.",
            "label": "Tesla Tracker",
            "shape": "box"
          },
          {
            "color": "lime",
            "id": "22",
            "info": "The process of canceling a Tesla order.",
            "label": "Cancellations",
            "shape": "box"
          },
          {
            "color": "lime",
            "id": "23",
            "info": "The process of canceling a Tesla order.",
            "label": "Order Cancellation",
            "shape": "box"
          },
          {
            "color": "lime",
            "id": "24",
            "info": "The time it takes to process a Tesla order cancellation.",
            "label": "Cancellation Processing Time",
            "shape": "box"
          },
          {
            "color": "lightgreen",
            "id": "25",
            "info": "The overarching category for all Tesla-related processes and features.",
            "label": "Tesla Operations",
            "shape": "box"
          }
        ]
      },

    example8: {

        "categories": [{"name": 'Account', "color": 'lightgreen'},
            {"name": 'Order', "color": 'lightblue'},
            {"name": 'Payment', "color": 'lightyellow'},
            {"name": 'Delivery', "color": 'orange'},
            {"name": 'Registration', "color": 'pink'},
            {"name": 'Charging', "color": 'lime'},
            {"name": 'Insurance', "color": 'lightgreen'},
            {"name": 'Tracking', "color": 'lightblue'},
            {"name": 'Document', "color": 'lightyellow'},
            {"name": 'Location', "color": 'orange'},
            {"name": 'Support', "color": 'pink'},
            {"name": 'Financing', "color": 'lime'},
            {"name": 'Global', "color": 'lightgreen'}],
        "nodes": [
    {
        "id": "1",
        "label": "Tesla Account",
        "color": "lightgreen",
        "shape": "box",
        "info": "An online account for Tesla customers to manage their orders, delivery, and other resources.",
        "references": [
            "After ordering your vehicle, set up a Tesla Account with the email address used to place your order. Complete your pre-delivery tasks in your Tesla Account to receive a delivery appointment.",
            "Your Tesla Account includes important updates, owner resources and guides regarding your delivery."
        ]
    },
    {
        "id": "31",
        "label": "Order Process",
        "color": "lightblue",
        "shape": "box",
        "info": "The overall process of managing Tesla vehicle orders, including placement and cancellation.",
        "references": [
            "I just designed my Tesla and submitted my order. What are the next steps?",
            "After ordering your vehicle, set up a Tesla Account with the email address used to place your order. Complete your pre-delivery tasks in your Tesla Account to receive a delivery appointment.",
            "How can I cancel my reservation or order?"
        ]
    },
    {
        "id": "2",
        "label": "Order Placement",
        "color": "lightblue",
        "shape": "box",
        "info": "The process of designing and submitting an order for a Tesla vehicle.",
        "references": [
            "I just designed my Tesla and submitted my order. What are the next steps?",
        ]
    },
    {
        "id": "3",
        "label": "Order Cancellation",
        "color": "lightblue",
        "shape": "box",
        "info": "The process of canceling a reservation or order for a Tesla vehicle.",
        "references": [
            "How can I cancel my reservation or order? ",
            "To cancel your reservation or order, contact an advisor.",
            "How long does it take until the cancellation is processed?",
            "We aim to process cancellation requests as quickly as possible. The cancellation process may take up to 30 days."
        ]
    },
    {
        "id": "32",
        "label": "Payment Process",
        "color": "lightyellow",
        "shape": "box",
        "info": "The overall process of managing payments for a Tesla vehicle, including methods and fees.",
        "references": [
            "What payment methods does Tesla accept?",
            "How should I submit my final payment?"
        ]
    },
    {
        "id": "4",
        "label": "Payment Method",
        "color": "lightyellow",
        "shape": "box",
        "info": "The method used to pay for a Tesla vehicle, including wire transfer, manager’s cheque, cash deposit, and financing.",
        "references": [
            "What payment methods does Tesla accept?",
            "Confirm your preferred payment method in your Tesla Account under ‘Payment Method’ or directly with your advisor."
        ]
    },
    {
        "id": "5",
        "label": "Final Payment",
        "color": "lightyellow",
        "shape": "box",
        "info": "The last payment made before taking delivery of a Tesla vehicle.",
        "references": [
            "How should I submit my final payment?",
            "To submit your final payment, view your final invoice in your Tesla Account and follow payment instructions."
        ]
    },
    {
        "id": "6",
        "label": "Destination and Doc Fee",
        "color": "lightyellow",
        "shape": "box",
        "info": "A fee that includes administration, registration, and documentation fees for a Tesla vehicle.",
        "references": [
            "Why is there a Destination and Doc Fee on my final invoice?",
            "The Destination and Doc Fee applies to all vehicles and includes administration, registration and documentation fees."
        ]
    },
    {
        "id": "7",
        "label": "Delivery Appointment",
        "color": "orange",
        "shape": "box",
        "info": "A scheduled appointment for the delivery of a Tesla vehicle.",
        "references": [
            "After ordering your vehicle, set up a Tesla Account with the email address used to place your order. Complete your pre-delivery tasks in your Tesla Account to receive a delivery appointment.",
        ]
    },
    {
        "id": "8",
        "label": "Delivery Date",
        "color": "orange",
        "shape": "box",
        "info": "The date on which a Tesla vehicle is delivered to the customer.",
        "references": [
            "I placed my order, when can I expect a delivery date?",
            "You will receive an email with your delivery date, which will show up in your Tesla Account as well."
        ]
    },
    {
        "id": "9",
        "label": "Vehicle Registration",
        "color": "pink",
        "shape": "box",
        "info": "The process of registering a Tesla vehicle with the provided information.",
        "references": [
            "How does registration work?",
            "We will register your vehicle with the information you provided in your Tesla Account."
        ]
    },
    {
        "id": "33",
        "label": "Charging Options",
        "color": "lime",
        "shape": "box",
        "info": "The various options available for charging a Tesla vehicle.",
        "references": [
            "Charging Solutions",
            "What are the local public charging options?",
            "How does home charging work?"
        ]
    },
    {
        "id": "10",
        "label": "Home Charging",
        "color": "lime",
        "shape": "box",
        "info": "Charging a Tesla vehicle at home using a Type 2 charging cable.",
        "references": [
            "How does home charging work?",
            "Your Tesla vehicle comes with a Type 2 charging cable."
        ]
    },
    {
        "id": "11",
        "label": "Public Charging",
        "color": "lime",
        "shape": "box",
        "info": "Charging a Tesla vehicle at public charging stations, including the Supercharger network.",
        "references": [
            "What are the local public charging options?",
            "Our Supercharger network is continuously being expanded."
        ]
    },
    {
        "id": "12",
        "label": "Motor Vehicle Insurance",
        "color": "lightgreen",
        "shape": "box",
        "info": "Insurance required before taking delivery of a Tesla vehicle.",
        "references": [
            "Am I required to have motor vehicle insurance prior to delivery?",
            "Yes, it is compulsory to have motor vehicle insurance before taking delivery of your Tesla."
        ]
    },
    {
        "id": "13",
        "label": "Tesla Tracker",
        "color": "lightblue",
        "shape": "box",
        "info": "A GPS tracking system to locate a Tesla vehicle through the Tesla app.",
        "references": [
            "Does my Tesla have a tracker?",
            "You can track your Tesla directly through your Tesla app, which uses a GPS tracking system to locate your car."
        ]
    },
    {
        "id": "34",
        "label": "Vehicle Documents",
        "color": "lightyellow",
        "shape": "box",
        "info": "Documents related to the purchase and registration of a Tesla vehicle.",
        "references": [
            "Where can I find documents in my Tesla Account?",
            "You can view your final invoice in your Tesla Account under ‘Documents’."
        ]
    },
    {
        "id": "14",
        "label": "Final Invoice",
        "color": "lightyellow",
        "shape": "box",
        "info": "The final invoice for a Tesla vehicle order, available in the Tesla Account.",
        "references": [
            "Where can I view my final invoice?",
            "You can view your final invoice in your Tesla Account under ‘Documents’."
        ]
    },
    {
        "id": "15",
        "label": "Registration Documents",
        "color": "lightyellow",
        "shape": "box",
        "info": "Documents required for the registration of a Tesla vehicle.",
        "references": [
            "Can I register my Tesla with two names, but only take out a loan in one name?",
            "How does registration work?"
        ]
    },
    {
        "id": "16",
        "label": "Tesla Service Center",
        "color": "orange",
        "shape": "box",
        "info": "A location where Tesla vehicle accessories can be purchased and services are provided.",
        "references": [
            "Where can I purchase accessories for my Tesla?",
            "You can purchase vehicle accessories at any Tesla Service Center."
        ]
    },
    {
        "id": "17",
        "label": "Tesla Account Support",
        "color": "pink",
        "shape": "box",
        "info": "Support resources for managing a Tesla Account and related tasks.",
        "references": [
            "For more information, visit Tesla Account Support.",
            "Tesla Account Support provides resources for managing a Tesla Account and related tasks."
        ]
    },
    {
        "id": "18",
        "label": "Tesla Preferred Partner",
        "color": "lime",
        "shape": "box",
        "info": "A partner that provides financing options for purchasing a Tesla vehicle.",
        "references": [
            "I’m financing my Tesla through a Tesla Preferred Partner.",
            "Tesla Preferred Partners offer financing options for purchasing a Tesla vehicle."
        ]
    },
    {
        "id": "35",
        "label": "Tesla FAQ Entity",
        "color": "lightgreen",
        "shape": "box",
        "info": "An overarching concept encompassing all aspects of Tesla vehicle management, from ordering to delivery and beyond.",
        "references": [
        ]
    }
],
        "edges": [
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts, including accounts.",
        "label": "type of",
        "source": "35",
        "target": "1",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts, including payments.",
        "label": "type of",
        "source": "35",
        "target": "32",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "7",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "9",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "33",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "12",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "13",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "34",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "16",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "17",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Tesla FAQ Entity generalizes all concepts.",
        "label": "type of",
        "source": "35",
        "target": "18",
        "type": "subtype",
        "references": [
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "A Tesla Account is used to manage orders, including placement and cancellation.",
        "label": "type of",
        "source": "1",
        "target": "31",
        "type": "subtype",
        "references": [
            "Your Tesla Account includes important updates",
            "You can view your final invoice in your Tesla Account under ‘Documents’.",
            "Confirm your preferred payment method in your Tesla Account under ‘Payment Method’ or directly with your advisor.",
            "To submit your final payment, view your final invoice in your Tesla Account and follow payment instructions.",

        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "Order Placement is part of the Order Process.",
        "label": "type of",
        "source": "31",
        "target": "2",
        "type": "subtype",
        "references": [
            "After ordering your vehicle, set up a Tesla Account with the email address used to place your order. Complete your pre-delivery tasks in your Tesla Account to receive a delivery appointment.",
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "Order Cancellation is part of the Order Process.",
        "label": "type of",
        "source": "31",
        "target": "3",
        "type": "subtype",
        "references": [
            "How can I cancel my reservation or order? ",
            "To cancel your reservation or order, contact an advisor."
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "Payment methods are part of the payment process for a Tesla vehicle.",
        "label": "type of",
        "source": "32",
        "target": "4",
        "type": "subtype",
        "references": [
            "What payment methods does Tesla accept?",
            "Details on accepted payment methods."
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The final invoice is a document related to the payment process.",
        "label": "type of",
        "source": "32",
        "target": "5",
        "type": "subtype",
        "references": [
            "How should I submit my final payment?",
            "Details on viewing and submitting the final invoice."
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "The Destination and Doc Fee is part of the payment process.",
        "label": "type of",
        "source": "32",
        "target": "6",
        "type": "subtype",
        "references": [
            "Why is there a Destination and Doc Fee on my final invoice?"
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "A delivery appointment is part of the delivery process.",
        "label": "type of",
        "source": "7",
        "target": "8",
        "type": "subtype",
        "references": [
            "We will pro-actively inform you once your delivery date is available. You will receive an email with your delivery date, which will show up in your Tesla Account as well. You can always view your estimated Delivery Window in your Tesla Account."
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "Home Charging is a type of Charging Option.",
        "label": "type of",
        "source": "33",
        "target": "10",
        "type": "subtype",
        "references": [
            "How does home charging work?"
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "Public Charging is a type of Charging Option.",
        "label": "type of",
        "source": "33",
        "target": "11",
        "type": "subtype",
        "references": [
            "What are the local public charging options?"
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "Registration documents are part of the vehicle registration process.",
        "label": "type of",
        "source": "34",
        "target": "15",
        "type": "subtype",
        "references": [
            "Can I register my Tesla with two names, but only take out a loan in one name?",
            "How does registration work?"
        ]
    },
    {
        "arrowhead": "normal",
        "color": "black",
        "info": "Final Invoice is a type of Vehicle Document.",
        "label": "type of",
        "source": "34",
        "target": "14",
        "type": "subtype",
        "references": [
            "Where can I view my final invoice?",
            "Final Invoice is a type of Vehicle Document."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "The Tesla Account is used to manage delivery appointments and dates.",
        "label": "manages",
        "source": "1",
        "target": "7",
        "type": "other",
        "references": [
            "Your Tesla Account includes important updates, owner resources and guides regarding your delivery. We encourage you to check your Tesla Account for updates regularly, and complete any pending actions needed.",
            "You will receive an email with your delivery date, which will show up in your Tesla Account as well. You can always view your estimated Delivery Window in your Tesla Account."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "The Tesla Account is used to manage delivery appointments and dates.",
        "label": "manages",
        "source": "1",
        "target": "8",
        "type": "other",
        "references": [
            "Your Tesla Account includes important updates, owner resources and guides regarding your delivery. We encourage you to check your Tesla Account for updates regularly, and complete any pending actions needed.",
            "We will pro-actively inform you once your delivery date is available. You will receive an email with your delivery date, which will show up in your Tesla Account as well. You can always view your estimated Delivery Window in your Tesla Account."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "The Tesla Account is used to manage payment methods and final payments.",
        "label": "manages",
        "source": "1",
        "target": "4",
        "type": "other",
        "references": [
            "Your Tesla Account includes important updates, owner resources and guides regarding your delivery. We encourage you to check your Tesla Account for updates regularly, and complete any pending actions needed.",
            "You can view your final invoice in your Tesla Account under ‘Documents’."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "The Tesla Account is used to manage payment methods and final payments.",
        "label": "manages",
        "source": "1",
        "target": "5",
        "type": "other",
        "references": [
            "Your Tesla Account includes important updates, owner resources and guides regarding your delivery. We encourage you to check your Tesla Account for updates regularly, and complete any pending actions needed.",
            "You can view your final invoice in your Tesla Account under ‘Documents’."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "The Tesla Account is used to manage vehicle registration.",
        "label": "manages",
        "source": "1",
        "target": "9",
        "type": "other",
        "references": [
            "We will register your vehicle with the information you provided in your Tesla Account."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "The Tesla Account is used to manage documents, including the final invoice and registration documents.",
        "label": "manages",
        "source": "1",
        "target": "14",
        "type": "other",
        "references": [
            "Your Tesla Account includes important updates, owner resources and guides regarding your delivery. We encourage you to check your Tesla Account for updates regularly, and complete any pending actions needed.",
            "You can view your final invoice in your Tesla Account under ‘Documents’."

        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "The Tesla Account is used to manage documents, including the final invoice and registration documents.",
        "label": "manages",
        "source": "1",
        "target": "15",
        "type": "other",
        "references": [
            "Your Tesla Account includes important updates, owner resources and guides regarding your delivery. We encourage you to check your Tesla Account for updates regularly, and complete any pending actions needed.",
            "We will register your vehicle with the information you provided in your Tesla Account. "
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "Tesla Service Centers provide locations for purchasing vehicle accessories.",
        "label": "provides",
        "source": "16",
        "target": "10",
        "type": "other",
        "references": [
            "You can purchase vehicle accessories at any Tesla Service Center."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "Tesla Service Centers provide locations for purchasing vehicle accessories.",
        "label": "provides",
        "source": "16",
        "target": "11",
        "type": "other",
        "references": [
            "You can purchase vehicle accessories at any Tesla Service Center."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "Tesla Preferred Partners offer financing options for purchasing a Tesla vehicle.",
        "label": "offers",
        "source": "18",
        "target": "4",
        "type": "other",
        "references": [
            "I’m financing my Tesla through a Tesla Preferred Partner."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "Tesla Preferred Partners offer financing options for purchasing a Tesla vehicle.",
        "label": "offers",
        "source": "18",
        "target": "5",
        "type": "other",
        "references": [
            "I’m financing my Tesla through a Tesla Preferred Partner."
        ]
    },
    {
        "arrowhead": "diamond",
        "color": "black",
        "info": "Tesla Account Support provides resources for managing a Tesla Account.",
        "label": "provides",
        "source": "17",
        "target": "1",
        "type": "other",
        "references": [
            "For more information, visit Tesla Account Support.",
            "Tesla Account Support provides resources for managing a Tesla Account and related tasks."
        ]
    }
    ],
    }
};