import React, { useState } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebase';

const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
      }
    } catch (error) {
      console.error(isLogin ? "Login error:" : "Sign-up error:", error.message);
      alert((isLogin ? "Login failed: " : "Sign-up failed: ") + error.message);
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="flex flex-col items-center justify-center px-4 mt-20">
      <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">
        Knowledge Graphs - The Future of AI
      </h1>
      
      <div className="max-w-sm w-full bg-white p-8 rounded-md shadow-md">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">
          {isLogin ? "Sign In" : "Create Account"}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-5">
          <div>
            <label className="block mb-1 font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // Background changed to a very light green with green border and focus
              className="w-full p-3 bg-[#F0F5EB] border border-[#A9C47C] rounded-md focus:outline-none focus:ring-2 focus:ring-[#A9C47C]"
              placeholder="you@example.com"
              required
            />
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              // Same styling for the password field
              className="w-full p-3 bg-[#F0F5EB] border border-[#A9C47C] rounded-md focus:outline-none focus:ring-2 focus:ring-[#A9C47C]"
              placeholder="••••••••"
              required
            />
          </div>
          <button
            type="submit"
            // Button remains the same lighter green on hover (no blue)
            className="w-full p-3 bg-[#A9C47C] text-white font-semibold rounded-md transition"
          >
            {isLogin ? "Log In" : "Sign Up"}
          </button>
        </form>
        <div className="mt-4 text-center">
          <button
            onClick={toggleMode}
            className="text-bg-[#243A5E] hover:underline font-medium"
          >
            {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Log In"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
