// src/features/species/components/upload/UploadForm.tsx

import { FileUpload } from "../features/FileUpload.tsx"
import { SpeciesSelect } from "../species/SpeciesSelect.tsx"
import { Button } from "../ui/button.tsx"

interface UploadFormProps {
  onSpeciesSelect: (value: string) => void
  onFileSelect: (file: File | null) => void
  onExtractData: () => void
  selectedFile: File | null
}

export const UploadForm = ({
  onSpeciesSelect,
  onFileSelect,
  onExtractData,
  selectedFile,
}: UploadFormProps) => {
  return (
    <div className="space-y-6">
      <div className="bg-white shadow rounded-lg p-8">
        <h2 className="text-lg font-medium mb-4">Select Species</h2>
        {/* Now this dropdown is populated from your backend */}
        <SpeciesSelect onSelect={onSpeciesSelect} />
      </div>

      <div className="bg-white shadow rounded-lg p-8">
        <h2 className="text-lg font-medium mb-4">Upload PDF Report</h2>
        <FileUpload onFileSelect={onFileSelect} />
        {selectedFile && <p>Selected file: {selectedFile.name}</p>}
      </div>

      <div className="flex justify-end">
        <Button onClick={onExtractData}>Extract Data</Button>
      </div>
    </div>
  )
}