import { useState } from "react"
import { Loader2 } from "lucide-react"
import { Card } from "../ui/card.tsx"
import { toast } from "sonner"
import { Button } from "../ui/button.tsx"

interface ImageSearchProps {
  speciesName: string
  onImageSelect?: (imgUrl: string) => void
}

export const ImageSearch = ({ speciesName, onImageSelect }: ImageSearchProps) => {
  const [images, setImages] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  async function handleGatherImages() {
    if (!speciesName) {
      toast.error("No species name provided!")
      return
    }
    setLoading(true)
    setError(null)
    try {
      const response = await fetch(
        `https://atlaz-api.com/species/api/images?query=${encodeURIComponent(speciesName)}`,
        { method: "GET" }
      )
      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.error || "Failed to fetch images.")
      }
      const data = await response.json()

      if (data.images && data.images.length > 0) {
        setImages(data.images)
      } else {
        setImages([])
        toast.error("No images found for this species.")
      }
    } catch (err: any) {
      console.error("Error fetching images:", err)
      setError(err.message || "An unexpected error occurred.")
      toast.error(err.message || "An unexpected error occurred while fetching images.")
    } finally {
      setLoading(false)
    }
  }

  async function handleImageClick(imgUrl: string) {
    try {
      const response = await fetch("https://atlaz-api.com/species/api/save-image", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          species_name: speciesName,
          image_url: imgUrl,
        }),
      })

      if (!response.ok) {
        throw new Error("Failed to save image")
      }

      const data = await response.json()
      toast.success(data.message)

      if (onImageSelect) {
        onImageSelect(imgUrl)
      }
    } catch (error) {
      toast.error("An error occurred while saving the image.")
      console.error("Error saving image:", error)
    }
  }

  return (
    <Card className="p-6">
      <h3 className="text-lg font-medium mb-4">
        Gather Images for: <em>{speciesName}</em>
      </h3>

      <Button onClick={handleGatherImages} disabled={loading}>
        {loading ? "Loading..." : "Gather Images"}
      </Button>

      {loading && (
        <div className="flex justify-center items-center mt-4">
          <Loader2 className="animate-spin h-6 w-6 text-primary" />
          <span className="ml-2">Loading images...</span>
        </div>
      )}

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {!loading && images.length > 0 && (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 mt-4">
          {images.map((imgUrl, index) => (
            <img
              key={index}
              src={imgUrl}
              alt={`${speciesName} result #${index + 1}`}
              className="object-cover w-full h-32 rounded-md border shadow hover:shadow-lg transition-shadow cursor-pointer"
              onClick={() => handleImageClick(imgUrl)}
            />
          ))}
        </div>
      )}

      {/* If we've gathered images but got zero results, and there's no error */}
      {!loading && images.length === 0 && !error && (
        <p className="text-gray-500 mt-4">
          No images available. Try gathering again or check your query.
        </p>
      )}
    </Card>
  )
}